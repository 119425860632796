import {
  Content,
  GlobalConfig,
  Image as SanityImage,
  getPositionFromHotspot,
} from "../../lib";
import Image from "next/image";
import {
  basicMonthlyPricesOnly,
  basicTwoYearPrices,
  basicYearlyPrices,
  premiumFiveYearPrices,
  premiumTwoYearPrices,
  premiumYearlyPrices,
  familyYearlyPrices,
  familyTwoYearPrices,
  familyFiveYearPrices,
} from "../../lib/subscriptionPrices";
import { useLocale } from "../../translations";
import { useContext } from "react";
import { CountryContext } from "../../context";
import PricingTable from "../pricingTable";

interface Props {
  header: string;
  subheader: string;
  subscriptions: Array<
    Content & {
      features: string[];
      missingFeatures: string[];
      tier: "basic" | "premium";
      title: string;
      description: any;
    }
  >;
  title: string;
  backgroundImage: SanityImage;
  description: any;
  SimpleBlockContent: any;
  urlFor: any;
  config: GlobalConfig;
  basicProductWithPrices?: any
  premiumProductWithPrices?: any;
  familyProductWithPrices?: any;
  forEverCoupon?: any;
}
const LocalizedSubscriptions = ({
  header,
  subheader,
  backgroundImage,
  description,
  title,
  SimpleBlockContent,
  urlFor,
  //TODO use prices from Stripe
  basicProductWithPrices,
  premiumProductWithPrices,
  familyProductWithPrices,
  forEverCoupon,
}: Props) => {
  const urlPrefix = useLocale().urlPrefix;
  const { country } = useContext(CountryContext);

  let discountedBasicMonthlyPricesOnly;
  let discountedBasicTwoYearPrices;
  let discountedBasicYearlyPrices;
  let discountedPremiumFiveYearPrices;
  let discountedPremiumTwoYearPrices;
  let discountedPremiumYearlyPrices;
  let discountedFamilyYearlyPrices;
  let discountedFamilyTwoYearPrices;
  let discountedFamilyFiveYearPrices;

  function applyDiscount(prices, discount) {
    const discounted = {};
    for (const currency in prices) {
      if (currency === "NOK" || currency === "SEK") {
        discounted[currency] = (prices[currency] * (1 - discount / 100)).toFixed(0);
      } else {
        discounted[currency] = (prices[currency] * (1 - discount / 100)).toFixed(2);
      }
    }
    return discounted;
  }

  const isCouponValid = forEverCoupon?.valid;

  if (isCouponValid && forEverCoupon?.percent_off) {
    const discount = forEverCoupon.percent_off;

    discountedBasicMonthlyPricesOnly = applyDiscount(basicMonthlyPricesOnly, discount);
    discountedBasicYearlyPrices = applyDiscount(basicYearlyPrices, discount);
    discountedBasicTwoYearPrices = applyDiscount(basicTwoYearPrices, discount);
    discountedPremiumFiveYearPrices = applyDiscount(premiumFiveYearPrices, discount);
    discountedPremiumTwoYearPrices = applyDiscount(premiumTwoYearPrices, discount);
    discountedPremiumYearlyPrices = applyDiscount(premiumYearlyPrices, discount);
    discountedFamilyYearlyPrices = applyDiscount(familyYearlyPrices, discount);
    discountedFamilyTwoYearPrices = applyDiscount(familyTwoYearPrices, discount);
    discountedFamilyFiveYearPrices = applyDiscount(familyFiveYearPrices, discount);
  }

  
  

  return (
    <div>
      <div className="absolute w-full h-[500px]">
        <Image
          src={urlFor(backgroundImage).url()}
          alt="background"
          layout="fill"
          objectFit="cover"
          objectPosition={
            backgroundImage.hotspot &&
            getPositionFromHotspot(backgroundImage.hotspot)
          }
        />
        <div className="absolute inset-0 bg-gradient-to-t from-primary-mono-1/80 to-transparent" />
      </div>
      <div className="relative mx-auto max-w-7xl px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-base font-semibold leading-7 text-white">
            {title}
          </h2>
          <div className="flex flex-col my-0 ">
            <p className="mt-2 text-4xl font-bold tracking-tight text-primary-mono-4 sm:text-5xl">
              {header}
            </p>
            <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
              {subheader}
            </p>
          </div>
        </div>
        <div className="relative mt-6">
          <div className="mx-auto max-w-2xl text-lg leading-8 text-white">
            <SimpleBlockContent blocks={description} />
          </div>
        </div>
      </div>
      <div className="relative flow-root bg-white pb-24 z-10 sm:pb-32">
        <div className="-mt-80 mb-6 flex flex-col space-y-4 px-2 font-objektiv md:space-y-0 md:space-x-4 md:flex-row">
          <PricingTable
            basicProductWithPrices={basicProductWithPrices}
            premiumProductWithPrices={premiumProductWithPrices}
            familyProductWithPrices={familyProductWithPrices}
            forEverCoupon={forEverCoupon}
            showOnFullScreen = {true} />
        </div>
      </div>
    </div>
  );
};

export default LocalizedSubscriptions;

const features = [
  {
    id: process.env.NEXT_PUBLIC_STRIPE_BASIC_PRODUCT_ID,
    features: [
      {
        no: "GPS-lokasjon",
        en: "GPS location",
        se: "GPS-plats",
        da: "GPS-placering",
        fi: "GPS-sijainti",
        de: "GPS-Standort",
        fr: "Emplacement GPS",
        es: "Ubicación GPS",
      },
      {
        no: "Livesporing",
        en: "Live tracking",
        se: "Live-spårning",
        da: "Live-tracking",
        fi: "Live-seuranta",
        de: "Live-Tracking",
        fr: "Suivi en direct",
        es: "Seguimiento en vivo",
      },
      {
        no: "Lokasjonshistorikk",
        en: "Location history",
        se: "Plats historia",
        da: "Placering historie",
        fi: "Sijaintihistoria",
        de: "Standortverlauf",
        fr: "Historique de localisation",
        es: "Historial de ubicaciones",
      },
      {
        no: "Lyd og lys",
        en: "Sound and light",
        se: "Ljud och ljus",
        da: "Lyd og lys",
        fi: "Ääni ja valo",
        de: "Ton und Licht",
        fr: "Son et lumière",
        es: "Sonido y luz",
      },
      {
        no: "Aktivitetsnivå",
        en: "Activity level",
        se: "Aktivitetsnivå",
        da: "Aktivitetsniveau",
        fi: "Aktiviteettitaso",
        de: "Aktivitätslevel",
        fr: "Niveau d'activité",
        es: "Nivel de actividad",
      },
    ],
    missingFeatures: [
      {
        no: "Inntil en ny enhet årlig ved tap/skade",
        en: "Up to one new device annually in case of loss/damage",
        se: "Upp till en ny enhet årligen vid förlust/stöld/skada",
        da: "Op til en ny enhed årligt i tilfælde af tab/skade",
        fi: "Enintään yksi uusi laite vuosittain tapauksessa vahinkoja/varastettu",
        de: "Bis zu einem neuen Gerät jährlich bei Verlust/Diebstahl/Schaden",
        fr: "Jusqu'à un nouvel appareil par an en cas de perte/vol/dommage",
        es: "Hasta un nuevo dispositivo anual en caso de pérdida/robo/daño",
      },
      {
        no: "Inntil 4 enheter på et abonnement",
        en: "Up to 4 devices on one subscription",
        se: "Upp till 4 enheter på en prenumeration",
        da: "Op til 4 enheder på et abonnement",
        fi: "Enintään 4 laitetta yhdellä tilauksella",
        de: "Bis zu 4 Geräte auf einem Abonnement",
        fr: "Jusqu'à 4 appareils sur un abonnement",
        es: "Hasta 4 dispositivos en una suscripción",
      },
    ],
  },
  {
    id: process.env.NEXT_PUBLIC_STRIPE_PREMIUM_PRODUCT_ID,
    features: [
      {
        no: "GPS-lokasjon",
        en: "GPS location",
        se: "GPS-plats",
        da: "GPS-placering",
        fi: "GPS-sijainti",
        de: "GPS-Standort",
        fr: "Emplacement GPS",
        es: "Ubicación GPS",
      },
      {
        no: "Livesporing",
        en: "Live tracking",
        se: "Live-spårning",
        da: "Live-tracking",
        fi: "Live-seuranta",
        de: "Live-Tracking",
        fr: "Suivi en direct",
        es: "Seguimiento en vivo",
      },
      {
        no: "Lokasjonshistorikk",
        en: "Location history",
        se: "Plats historia",
        da: "Placering historie",
        fi: "Sijaintihistoria",
        de: "Standortverlauf",
        fr: "Historique de localisation",
        es: "Historial de ubicaciones",
      },
      {
        no: "Lyd og lys",
        en: "Sound and light",
        se: "Ljud och ljus",
        da: "Lyd og lys",
        fi: "Ääni ja valo",
        de: "Ton und Licht",
        fr: "Son et lumière",
        es: "Sonido y luz",
      },
      {
        no: "Aktivitetsnivå",
        en: "Activity level",
        se: "Aktivitetsnivå",
        da: "Aktivitetsniveau",
        fi: "Aktiviteettitaso",
        de: "Aktivitätslevel",
        fr: "Niveau d'activité",
        es: "Nivel de actividad",
      },
      {
        no: "Inntil  en ny enhet årlig ved tap/skade",
        en: "Up to one new device annually in case of loss/damage",
        se: "Upp till en ny enhet årligen vid förlust/stöld/skada",
        da: "Op til en ny enhed årligt i tilfælde af tab/skade",
        fi: "Enintään yksi uusi laite vuosittain tapauksessa vahinkoja/varastettu",
        de: "Bis zu einem neuen Gerät jährlich bei Verlust/Diebstahl/Schaden",
        fr: "Jusqu'à un nouvel appareil par an en cas de perte/vol/dommage",
        es: "Hasta un nuevo dispositivo anual en caso de pérdida/robo/daño",
      },
    ],
    missingFeatures: [
      {
        no: "Inntil 4 enheter på et abonnement",
        en: "Up to 4 devices on one subscription",
        se: "Upp till 4 enheter på en prenumeration",
        da: "Op til 4 enheder på et abonnement",
        fi: "Enintään 4 laitetta yhdellä tilauksella",
        de: "Bis zu 4 Geräte auf einem Abonnement",
        fr: "Jusqu'à 4 appareils sur un abonnement",
        es: "Hasta 4 dispositivos en una suscripción",
      },
    ],
  },
  {
    id: process.env.NEXT_PUBLIC_STRIPE_FAMILY_PRODUCT_ID,
    features: [
      {
        no: "GPS-lokasjon",
        en: "GPS location",
        se: "GPS-plats",
        da: "GPS-placering",
        fi: "GPS-sijainti",
        de: "GPS-Standort",
        fr: "Emplacement GPS",
        es: "Ubicación GPS",
      },
      {
        no: "Livesporing",
        en: "Live tracking",
        se: "Live-spårning",
        da: "Live-tracking",
        fi: "Live-seuranta",
        de: "Live-Tracking",
        fr: "Suivi en direct",
        es: "Seguimiento en vivo",
      },
      {
        no: "Lokasjonshistorikk",
        en: "Location history",
        se: "Plats historia",
        da: "Placering historie",
        fi: "Sijaintihistoria",
        de: "Standortverlauf",
        fr: "Historique de localisation",
        es: "Historial de ubicaciones",
      },
      {
        no: "Lyd og lys",
        en: "Sound and light",
        se: "Ljud och ljus",
        da: "Lyd og lys",
        fi: "Ääni ja valo",
        de: "Ton und Licht",
        fr: "Son et lumière",
        es: "Sonido y luz",
      },
      {
        no: "Aktivitetsnivå",
        en: "Activity level",
        se: "Aktivitetsnivå",
        da: "Aktivitetsniveau",
        fi: "Aktiviteettitaso",
        de: "Aktivitätslevel",
        fr: "Niveau d'activité",
        es: "Nivel de actividad",
      },
      {
        no: "Inntil  en ny enhet årlig ved tap/skade",
        en: "Up to one new device annually in case of loss/damage",
        se: "Upp till en ny enhet årligen vid förlust/stöld/skada",
        da: "Op til en ny enhed årligt i tilfælde af tab/skade",
        fi: "Enintään yksi uusi laite vuosittain tapauksessa vahinkoja/varastettu",
        de: "Bis zu einem neuen Gerät jährlich bei Verlust/Diebstahl/Schaden",
        fr: "Jusqu'à un nouvel appareil par an en cas de perte/vol/dommage",
        es: "Hasta un nuevo dispositivo anual en caso de pérdida/robo/daño",
      },
      {
        no: "Inntil 4 enheter på et abonnement",
        en: "Up to 4 devices on one subscription",
        se: "Upp till 4 enheter på en prenumeration",
        da: "Op til 4 enheder på et abonnement",
        fi: "Enintään 4 laitetta yhdellä tilauksella",
        de: "Bis zu 4 Geräte auf einem Abonnement",
        fr: "Jusqu'à 4 appareils sur un abonnement",
        es: "Hasta 4 dispositivos en una suscripción",
      },
    ],
    missingFeatures: [],
  },
];

const translations = {
  family: {
    en: "Family",
    no: "Familie",
    se: "Familj",
    da: "Familie",
    fi: "Perhe",
    de: "Familie",
    fr: "Famille",
    es: "Familia",
  },
  save: {
    en: "Save",
    no: "Spar",
    se: "Spara",
    da: "Spar",
    fi: "Säästä",
    de: "Sparen",
    fr: "Économisez",
    es: "Ahorra",
  },
  premium: {
    en: "Premium",
    no: "Premium",
    se: "Premium",
    da: "Premium",
    fi: "Premium",
    de: "Premium",
    fr: "Premium",
    es: "Premium",
  },
  basic: {
    en: "Basic",
    no: "Basic",
    se: "Basic",
    da: "Basic",
    fi: "Basic",
    de: "Basic",
    fr: "Basic",
    es: "Basic",
  },
  whatsIncluded: {
    en: "What’s included",
    no: "Hva er inkludert",
    se: "Vad ingår",
    da: "Hvad er inkluderet",
    fi: "Mitä sisältyy",
    de: "Was ist enthalten",
    fr: "Ce qui est inclus",
    es: "Qué está incluido",
  },
  priceFrom: {
    en: "from",
    no: "fra",
    se: "från",
    da: "fra",
    fi: "alkaen",
    de: "von",
    fr: "de",
    es: "de",
  },
  priceSuffix: {
    en: "/month",
    no: "/mnd",
    se: "/månad",
    da: "/måned",
    fi: "/kuukausi",
    de: "/Monat",
    fr: "/mois",
    es: "/mes",
  },
};
