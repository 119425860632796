/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import CurrencySwitcher from "./CurrencySwitcher";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { withRouter, NextRouter } from "next/router";
import { useLocale, Product, GlobalConfig, Locale } from "@lilbit/shared";
import LocaleSwitcher from "./localeSwitcher";

type Props = {
  router: NextRouter;
  config: GlobalConfig;
  urlFor: Function;
  siteName: "lildog" | "lilcat";
  mainProduct: Product;
};

export const hideNewsAndDogbreedsForTheseLocales = [
  "se",
  "da",
  "fi",
  "de",
  "ee",
  "es",
  "nl",
  "fr",
];

const NavBar = ({ router, config, urlFor, siteName, mainProduct }: Props) => {
  const urlPrefix = useLocale().urlPrefix;
  const navRef = useRef<HTMLDivElement>(null);
  const disclosureBtnRef = useRef<HTMLButtonElement>(null);
  const [isAgriaPage, setIsAgriaPage] = useState(false);

  useEffect(() => {
    if (window !== undefined) {
      setIsAgriaPage(window.location.href.includes("agria"));
    }
  }, []);

  const slugsForAllCategories = config?.slugsForAllCategories
    ? config.slugsForAllCategories
    : "";
  const slugsForAllLocales = config?.slugsForAllLocales
    ? config.slugsForAllLocales
    : "";

  /* Used to include url variables when changing language */
  let urlAsArray = Object.entries(router.query);
  const urlExtraVariables = urlAsArray.filter(
    ([key, _]) => key !== "category" && key !== "lang" && key !== "orderId"
  );
  const handleLocaleChange = (e: Locale) => {
    let extraVariables = "";
    if (urlExtraVariables) {
      urlExtraVariables.map((variable, index) => {
        if (index === 0)
          extraVariables += "?" + variable[0] + "=" + variable[1];
        else extraVariables += "&" + variable[0] + "=" + variable[1];
      });
    }
    const slug = slugsForAllLocales
      ? slugsForAllLocales[e.urlPrefix]?.current
      : "";
    const category = slugsForAllCategories
      ? slugsForAllCategories[e.urlPrefix]?.current
      : "";

    router.push(
      `/${e.urlPrefix}${category ? "/" + category : ""}${
        slug ? "/" + slug : ""
      }${extraVariables ? extraVariables : ""}`
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!navRef.current.contains(event.target as Node)) {
        disclosureBtnRef?.current?.click();
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [navRef, disclosureBtnRef]);

  const scrollToPayment = () => {
    const targetComponent = document.getElementById(
      "scroll-to-purchase-section"
    );
    if (targetComponent) {
      const targetPosition = targetComponent.getBoundingClientRect().top;
      const offset = 50;

      window.scrollTo({
        top: window.scrollY + targetPosition - offset,
        behavior: "smooth",
      });
    }
  };
  const handleBuyBtnClick = async (isHomePage: boolean) => {
    if (!isHomePage && !isAgriaPage) {
      await router.push(`/${urlPrefix}`).then(() => {
        scrollToPayment();
      });
    } else {
      scrollToPayment();
    }
  };

  return (
    <Disclosure as="nav" className="bg-white">
      {({ open }) => (
        <div
          ref={navRef}
          className="flex flex-col relative items-center justify-between px-4 py-4 max-w-screen-2xl mx-auto lg:px-0"
        >
          <div className="w-full">
            <div className="relative flex justify-between w-full">
              <div className="absolute inset-y-0 left-0 flex items-center">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-primary-mono-2 hover:text-primary-mono-2 hover:bg-primary-[#ebffc7] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-mono-2">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 xl:pl-0 hover:cursor-pointer">
                {localizedExternalPurchaseUrls[siteName][urlPrefix] ? (
                  <a
                    href={localizedExternalPurchaseUrls[siteName][urlPrefix]}
                    className="px-2 rounded-lg font-objektiv bg-primary-3 text-primary-2 border-b border-transparent hover:underline text-[16px] leading-[37px] transition hover:scale-105"
                  >
                    {translations.buyNow[urlPrefix]}
                  </a>
                ) : (
                  <>
                    {translations.subscriptionCategory[urlPrefix] !==
                      router.query.category &&
                      translations.checkoutCategory[urlPrefix] !==
                        router.query.category && (
                        <Link
                          href={{
                            pathname: "/[lang]/[category]",
                          }}
                          as={`/${urlPrefix}/${
                            config?.checkoutSlug?.current || "checkout"
                          }`}
                        >
                          <a className="px-2 rounded-lg font-objektiv bg-primary-3 text-primary-2 border-b border-transparent hover:underline text-[16px] leading-[37px] transition hover:scale-105">
                            {translations.buyNow[urlPrefix]}
                          </a>
                        </Link>
                        // <a
                        //   onClick={() => {
                        //     handleBuyBtnClick(
                        //       !router.query.category && !router.query.orderId
                        //     );
                        //   }}
                        //   className="px-2 rounded-lg font-objektiv bg-primary-3 text-primary-2 border-b border-transparent hover:underline text-[16px] leading-[37px] transition hover:scale-105 "
                        // >
                        //   {translations.buyNow[urlPrefix]}
                        // </a>
                      )}
                  </>
                )}
              </div>
              <div className="flex items-center w-full justify-between">
                <div className="flex-shrink-0 flex items-center justify-center w-full">
                  <Link as={`/${urlPrefix}`} href="/[lang]">
                    <a>
                      <div className="h-[40px] w-[70px] relative hover:cursor-pointer sm:w-[100px]">
                        {config?.logo && (
                          <Image
                            src={urlFor(config?.logo, true).quality(45).url()}
                            layout="fill"
                            objectFit="contain"
                            alt="lildog logo"
                            priority
                          />
                        )}
                      </div>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* Mobile locale buttons */}
          <Disclosure.Panel className="w-full">
            <div className="pt-2 pb-4 space-y-1">
              <div className="flex items-center pl-4 space-x-2">
                <LocaleSwitcher
                  handleLocaleChange={handleLocaleChange}
                  config={config}
                  siteName={siteName}
                />
                <CurrencySwitcher />
              </div>
              {config?.mainNavigation?.map((navigationItem, index) => {
                const { slug } = navigationItem;
                const isActive =
                  router.pathname === "/[lang]/[category]" &&
                  router.query.category === slug.current;
                return (
                  <Disclosure.Button
                    as="div"
                    key={navigationItem._id}
                    className="w-md"
                  >
                    <Link
                      href={{
                        pathname: "/[lang]/[category]",
                      }}
                      as={`/${urlPrefix}/${navigationItem.slug.current}`}
                      key={`${navigationItem.title}-${index}`}
                      passHref
                    >
                      <a
                        className={`border-transparent text-primary-mono-1 hover:underline block pl-3 pr-4 py-2 border-l-4 text-base font-medium ${
                          isActive && "underline"
                        }`}
                      >
                        {navigationItem.title}
                      </a>
                    </Link>
                  </Disclosure.Button>
                );
              })}
              {/* This button is triggered when clicking outside the navbar so that the navbar closes */}
              <Disclosure.Button ref={disclosureBtnRef} />
            </div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
};

export default withRouter(NavBar);

export const localizedExternalPurchaseUrls = {
  lilcat: {
    // no: "https://www.elkjop.no/product/mobil-nettbrett-og-smartklokker/mobiltilbehor/gps-og-bluetooth-tracker/lilcat-gps-sporingsbrikke-for-katter/721935",
    // en: "https://www.elgiganten.se/product/mobiler-tablets-smartklockor/mobiltillbehor/gps-tracker-bluetooth-tracker/lildog-gps-tracker-for-katter/721935",
  },
  lildog: {
    // no: "https://www.petxl.no/lildog-gps-tracker-for-hund",
    // se: "https://www.elgiganten.se/product/mobiler-tablets-smartklockor/mobiltillbehor/gps-tracker-bluetooth-tracker/lildog-gps-tracker-for-hundar/721934",
    // da: "https://www.elgiganten.dk/product/mobil-tablet-smartwatch/mobiltilbehor/gps-bluetooth-tracker/lildog-gps-tracker-til-hunde/721934",
    // fi: "https://www.petenkoiratarvike.com/koirat/harrastukset-ja-koulutus/metsastys-ja-jaljestys/lildog-gps-paikannin-koirille/204998",
  },
};

const translations = {
  homePageTitle: {
    en: "Home",
    no: "Hjem",
    se: "Hem",
    da: "Hjem",
    fi: "Koti",
    de: "Zuhause",
    fr: "Accueil",
    es: "Inicio",
  },
  buyNow: {
    en: "Buy now",
    no: "Kjøp nå",
    se: "Köp nu",
    da: "Køb nu",
    fi: "Osta nyt",
    de: "Jetzt kaufen",
    fr: "Acheter maintenant",
    es: "Comprar ahora",
  },
  subscriptionCategory: {
    en: "subscriptions",
    no: "abonnement",
    se: "abonnemang",
    da: "abonnementer",
    fi: "tilaukset",
    de: "abonnements",
    fr: "abonnements",
    es: "suscripciones",
  },
  checkoutCategory: {
    en: "checkout",
    no: "checkout",
    se: "checkout",
    da: "checkout",
    fi: "checkout",
    de: "checkout",
  },
};
