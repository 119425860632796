import { Image as ImageType } from "../../lib/types";
import Image from "next/image";
import { useLocale } from "../../translations";
import { useSitename } from "../../context/SitenameContext";

interface Props {
  mainText: string;
  icons: Array<{
    text: Array<any>;
    image: ImageType & {
      width?: number;
      height?: number;
      mobileWidth?: number;
      mobileHeight?: number;
    };
    _key: string;
    _type: string;
  }>;
  urlFor: Function;
  SimpleBlockContent: any;
}
const LocalizedHeaderAndIconsNoBackground = (props: Props) => {
  const { mainText, icons, urlFor, SimpleBlockContent } = props;
  const locale = useLocale().urlPrefix;
  const { siteName } = useSitename();

  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {mainText && (
          <SimpleBlockContent
            blocks={mainText}
            className="py-2 font-p22 font-bold text-center text-xl lg:text-2xl"
          />
        )}
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-2 items-center gap-x-8 gap-y-10 sm:grid-cols-4 sm:gap-x-10 lg:mx-0 sm:max-w-none lg:grid-cols-5">
          {icons?.map((item) => {
            const { text, image, _key } = item;
            const { width, height, mobileWidth, mobileHeight } = image;
            return (
              <div key={_key} className="relative">
                <Image
                  src={urlFor(image, true).url()}
                  objectFit="contain"
                  alt={image.alt || ""}
                  width={158}
                  height={48}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  return (
    <div className="relative w-full bg-white flex flex-col items-center justify-center py-4 sm:py-6 md:py-8">
      {mainText && (
        <SimpleBlockContent
          blocks={mainText}
          className="py-2 font-p22 font-bold text-center text-xl lg:text-2xl"
        />
      )}
      <div className="flex flex-col h-full w-full justify-evenly">
        {/* <div className="flex flex-wrap justify-evenly items-center mx-4 sm:mx-16 my-8"> */}
        <div className="grid grid-cols-2 lg:grid-cols-5 gap-10 justify-evenly items-center mx-4 sm:mx-16 my-8">
          {icons?.map((item) => {
            const { text, image, _key } = item;
            const { width, height, mobileWidth, mobileHeight } = image;
            return (
              <div key={_key} className="relative">
                {/* <div className="flex md:hidden justify-center">
                  <Image
                    src={urlFor(image, true).url()}
                    height={mobileHeight || 60}
                    width={mobileWidth || 60}
                    alt={image.alt || ""}
                  />
                </div> */}
                {/* <div className="hidden md:flex md:justify-center"> */}
                <div className="relative w-[160px] h-[80px]">
                  <Image
                    src={urlFor(image, true).url()}
                    layout="fill"
                    objectFit="contain"
                    alt={image.alt || ""}
                  />
                </div>
                <div>
                  <SimpleBlockContent
                    blocks={text}
                    className="pb-2 max-w-[100px] sm:max-w-[140px] px-4 text-xs sm:text-sm md:text-base font-medium text-center font-objektiv"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LocalizedHeaderAndIconsNoBackground;
