import Image from "next/image";
import { GlobalConfig, Image as ImageType } from "../../lib/types";
import { Product } from "../../lib";
import { getPositionFromHotspot } from "../../lib/helpers";
import AddToCart from "../Button/addToCartBtn";

interface Props {
  mainProduct: Product;
  bigScreenImage: ImageType;
  smallScreenImage: ImageType;
  urlFor: Function;
  config: GlobalConfig;
  buyButtonText: string;
}

const LocalizedImageForCampaign = (props: Props) => {
  const {
    bigScreenImage,
    smallScreenImage,
    config,
    urlFor,
    mainProduct,
    buyButtonText,
  } = props;

  return (
    <div
      className={`bg-secondary-3 w-full flex justify-center pb-12`}
    >
      <div className="hidden lg:flex relative h-full w-full items-center justify-center">
        <div className="relative">
          <Image
            src={urlFor(bigScreenImage, true).quality(100).url()}
            objectFit="contain"
            priority={true}
            height={600}
            width={1300}
            alt={bigScreenImage?.alt || ""}
            quality={100}
          />
          {buyButtonText && <div className="max-w-[220px] xl:max-w-xs absolute ml-72 inset-0 flex items-center justify-center">
            <AddToCart
              config={config}
              mainProduct={mainProduct}
              buyBtnText={buyButtonText}
            />
          </div>}
        </div>

      </div>
      <div className="flex lg:hidden relative items-center justify-center">
        <div className="relative">
          <Image
            src={urlFor(smallScreenImage, true).quality(100).url()}
            objectFit="contain"
            priority={true}
            height={600}
            width={600}
            alt={smallScreenImage?.alt || ""}
            quality={100}
            objectPosition={
              smallScreenImage?.hotspot && getPositionFromHotspot(smallScreenImage.hotspot)
            }
          />
          {buyButtonText && <div className="max-w-[140px] sm:max-w-[180px] absolute ml-4 sm:ml-6 inset-0 flex items-center justify-center">
            <AddToCart
              config={config}
              mainProduct={mainProduct}
              buyBtnText={buyButtonText}
            />
          </div>}
        </div>
      </div>
    </div>
  );
};

export default LocalizedImageForCampaign;
