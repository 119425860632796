import Image from "next/image";
import {
  getPositionFromHotspot,
  Image as ImageProps,
  SectionProps,
} from "../../lib";

interface Props extends SectionProps {
  header: string;
  logos: Array<ImageProps & { _key: string; _type: string }>;
}
const LogoBanner = ({ header = "", logos, urlFor }: Props) => {
  if (!logos) return null;
  return (
    <div className="bg-white py-24 sm:py-32">
      <h2 className="p-2 text-black font-p22 font-bold text-center text-xl lg:text-2xl">
        {header}
      </h2>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-2 items-center gap-x-8 gap-y-10 sm:grid-cols-3 sm:gap-x-10 xl:mx-0 sm:max-w-none xl:grid-cols-6">
          {logos?.map((logo) => {
            return (
              <Image
                key={logo._key}
                src={urlFor(logo).url()}
                objectFit="contain"
                alt="logo"
                width={158}
                height={48}
                objectPosition={
                  logo.hotspot && getPositionFromHotspot(logo.hotspot)
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full flex flex-col items-center justify-center mt-10 mb-20 space-y-10">
      <h3 className="p-2 text-primary-mono-1 font-p22 font-medium text-center text-xl lg:text-2xl">
        {header}
      </h3>
      <div className="grid grid-cols-2 gap-10 md:grid-cols-6">
        {logos?.map((logo) => {
          return (
            <div
              className="relative w-[150px] h-[80px] lg:w-[220px] lg:h-[100px]"
              key={logo._key}
            >
              <Image
                src={urlFor(logo).url()}
                layout="fill"
                objectFit="contain"
                alt="logo"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

function Example() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-lg/8 font-semibold text-gray-900">
          Trusted by the world’s most innovative teams
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img
            alt="Transistor"
            src="https://tailwindui.com/plus/img/logos/158x48/transistor-logo-gray-900.svg"
            width={158}
            height={48}
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
          />
          <img
            alt="Reform"
            src="https://tailwindui.com/plus/img/logos/158x48/reform-logo-gray-900.svg"
            width={158}
            height={48}
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
          />
          <img
            alt="Tuple"
            src="https://tailwindui.com/plus/img/logos/158x48/tuple-logo-gray-900.svg"
            width={158}
            height={48}
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
          />
          <img
            alt="SavvyCal"
            src="https://tailwindui.com/plus/img/logos/158x48/savvycal-logo-gray-900.svg"
            width={158}
            height={48}
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
          />
          <img
            alt="Statamic"
            src="https://tailwindui.com/plus/img/logos/158x48/statamic-logo-gray-900.svg"
            width={158}
            height={48}
            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
          />
        </div>
      </div>
    </div>
  );
}

export default LogoBanner;
