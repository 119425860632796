import Image from "next/image";
import { GlobalConfig, Image as ImageType, Product } from "@lilbit/shared";
import AddToCart from "../Button/addToCartBtn";

type Props = {
  icons: { icon: ImageType; text: string; _key: string }[];
  image: ImageType;
  header: string;
  subText: string;
  buyButtonText: string;
  config: GlobalConfig;
  mainProduct: Product;
  urlFor: Function;
  secondSubText: string;
  secondHeader: string;
};

const LocalizedAppMain = (props: Props) => {
  const {
    mainProduct,
    config,
    image,
    buyButtonText,
    header,
    icons,
    subText,
    urlFor,
    secondHeader,
    secondSubText
  } = props;
  return (
    <div className="bg-secondary-3 pb-12 pt-12 lg:pt-24">
      <div className="w-full max-w-7xl mx-auto font-objektiv">
        <div className="relative isolate">
          <div className="mx-auto px-6 w-full lg:flex lg:items-center">
            <div className="w-full space-y-10 lg:w-2/3">
              <h1 className="mt-10 max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                {header}
              </h1>
              <div>
                <p className="my-2 text-lg leading-8 text-gray-600 lg:max-w-2xl lg:my-4">
                  {subText}
                </p>
                <h2 className="mt-6 max-w-xl text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">{secondHeader??''}</h2>
                <p className="mb-1 text-lg leading-8 text-gray-600 lg:max-w-2xl lg:my-4">{secondSubText??''}</p>
                <div className="justify-center items-center flex w-full py-12 lg:hidden">
                  <Image
                    src={urlFor(image).url()}
                    width={348}
                    height={720}
                    quality={100}
                  />
                </div>
                <div className="max-w-xs">
                  <AddToCart
                    config={config}
                    mainProduct={mainProduct}
                    buyBtnText={buyButtonText}
                  />
                </div>
              </div>
              <div>
                <dl className="mt-4 grid grid-cols-2 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
                  {icons.map((stat) => (
                    <div
                      key={stat._key}
                      className="flex flex-col bg-gray-400/5 p-8"
                    >
                      <Image
                        src={urlFor(stat.icon).url()}
                        width={48}
                        height={48}
                        objectFit="contain"
                      />
                      <dt className="text-xs font-semibold leading-6 text-gray-600 lg:text-sm">
                        {stat.text}
                      </dt>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <div className="hidden justify-center items-center pt-0 w-1/3 lg:flex">
              <Image
                src={urlFor(image).url()}
                width={348}
                height={720}
                quality={100}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalizedAppMain;
