export const basicYearlyPrices = {
  NOK: 948,
  EUR: 80.86,
  USD: 88.2,
  SEK: 948,
  DKK: 615,
};
export const basicTwoYearPrices = {
  NOK: 1656,
  EUR: 120.77,
  USD: 131.74,
  SEK: 1656,
  DKK: 1075,
};
export const premiumYearlyPrices = {
  NOK: 1068,
  EUR: 91.09,
  USD: 99.36,
  SEK: 1068,
  DKK: 693,
};
export const premiumTwoYearPrices = {
  NOK: 1896,
  EUR: 161.86,
  USD: 176.23,
  SEK: 1896,
  DKK: 1230,
};
export const premiumFiveYearPrices = {
  NOK: 2940,
  EUR: 250.76,
  USD: 273.52,
  SEK: 2940,
  DKK: 1908,
};
export const basicMonthlyPricesOnly = {
  NOK: 149,
  EUR: 12.71,
  USD: 13.86,
  SEK: 149,
  DKK: 97,
};

export const familyYearlyPrices = {
  NOK: 1908,
  EUR: 199,
  USD: 199,
  SEK: 1908,
  DKK: 1049,
};

export const familyTwoYearPrices = {
  NOK: 2856,
  EUR: 244,
  USD: 263,
  SEK: 2815,
  DKK: 1821,
};

export const familyFiveYearPrices = {
  NOK: 4980,
  EUR: 425,
  USD: 460,
  SEK: 4980,
  DKK: 3230,
};

export const subscriptionPrices = {
  basic: {
    monthly: basicMonthlyPricesOnly,
    yearly: basicYearlyPrices,
  },
  premium: {
    monthly: {
      NOK: premiumYearlyPrices.NOK / 12,
      EUR: (premiumYearlyPrices.EUR / 12).toFixed(2),
      USD: (premiumYearlyPrices.USD / 12).toFixed(2),
      SEK: (premiumYearlyPrices.SEK / 12).toFixed(2),
      DKK: (premiumYearlyPrices.DKK / 12).toFixed(2),
    },
    yearly: premiumYearlyPrices,
  },
  family: {
    monthly: {
      NOK: familyYearlyPrices.NOK / 12,
      EUR: (familyYearlyPrices.EUR / 12).toFixed(2),
      USD: (familyYearlyPrices.USD / 12).toFixed(2),
      SEK: (familyYearlyPrices.SEK / 12).toFixed(2),
      DKK: (familyYearlyPrices.DKK / 12).toFixed(2),
    },
    yearly: familyYearlyPrices,
  },
};
