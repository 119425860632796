export const frontpageConfigQuery = `*[_id == "global-config"] {
    ...,
    mainNavigation[] -> {
      ...,
      "title": page->title[$sanityLanguageIdentifier],
      'dependantOnAuth': page->content[0]{
      dependantOnAuth
    },
      "userAuthedTitle": page->content[].signedInTitle[$sanityLanguageIdentifier],
      "slug": slug[$sanityLanguageIdentifier],
      "locale": $sanityLanguageIdentifier,
    }
  }[0]`;
export const storesSlugsQuery = `*[_type == "localizedRoute" && !(_id in path("drafts.**")) && groupid == "stores"]{slug}`;
export const routeSlugsQuery = `*[_type == "localizedRoute" && !(_id in path("drafts.**"))] {language, slug, groupid}`;
export const postSlugsQuery = `*[_type == "post" && !(_id in path("drafts.**"))] {slug,'category': categories[0]->slug}`;
export const categoriesSlugsQuery = `*[_type == "route"]{slug}`;
//export const breedsFromArticlesQuery = `*[_type == 'post' && categories[0]->slug['en'].current == 'dogbreeds']{title, ...}`;
export const breedsQuery = `*[_type == "breeds"] | order(name.en) {name, related_articles, _id}`;
export const frontPageQuery = `*[_id == "frontpage" || _id == "frontpage-b"]{
    title,
    description,
    meta_image,
    content[] {
      ...,
      _type in ['antiStories'] => {
        ...,
        stories[]{
          'story': *[_type == 'post' && _id == ^._ref][0]{
            'name': author->name,
            'authorImage': author->image,
            'blogCategoryTitle': blogCategory[0]->title.[$sanityLanguageIdentifier],
            'category': categories[0]->slug[$sanityLanguageIdentifier].current,
            'blogOrDog': categories[0]->title,
            summary,
            title,
            _updatedAt,
            slug,
            mainImage
          }
        }
      },
      _type == "sectiontwentythree" => {
        ...,
        "video_ref": video {
          ...,
          asset->{
            ...,
            "url": "https://stream.mux.com/" + playbackId
          }
        },
      },
    },
  }`;
  export const newFrontPageQuery = (
    lang: string
  ) => `*[_id == "localizedFrontpage-${lang}"]{
    title,
    description,
    meta_image,
    content[] {
      ...,
      _type == "localizedNewImageAndProductInfo" => {
        images[] {
              ...,
              asset->{
                ...,
                "url": "https://stream.mux.com/" + playbackId
          }
        },
      },
      _type == "localizedSectionReels" => {
        reels[] {
          ...,
          asset->{
            ...,
            "url": "https://stream.mux.com/" + playbackId
          }
        }
      },
      _type == "localizedThreePointOverview" => {
        "video": video {
          ...,
          asset->{
            ...,
            "url": "https://stream.mux.com/" + playbackId
          }
        }
      },
    },
  } `;

export const newFrontpageConfigQuery = `*[_type == "localizedSiteConfig" && language == $sanityLanguageIdentifier] {
  ...,
  mainNavigation[] -> {
    ...,
    "title": localizedPage->title,
    'dependantOnAuth': localizedPage->content[0]{
    dependantOnAuth
  },
    "userAuthedTitle": localizedPage->content[].signedInTitle,
    "slug": slug,
    "locale": $sanityLanguageIdentifier,
  },
  "checkoutSlug": *[_type == "localizedRoute" && language == $sanityLanguageIdentifier && groupid == "checkout"]{
    "current": slug.current,
    "_type": slug._type
  }[0]
}[0]`;

export const postPageQuery = `
  *[_type == 'post' && !(_id in path("drafts.**")) && slug[$sanityLanguageIdentifier].current == $slug && categories[0]->slug[$sanityLanguageIdentifier].current == $route][0]{
    'name': author->name,
    'authorImage': author->image,
    'authorBio': author->bio,
    'category': categories[0]->slug[$sanityLanguageIdentifier].current,
    'blogCategory': blogCategory[0]->slug[$sanityLanguageIdentifier].current,
    'localeCategory': categories[0]->slug,
    body,
    seoTitle,
    meta,
    ...,
  }`;

export const newIndexPageQuery = `*[_type == "localizedRoute" && slug.current == $slug && language == $sanityLanguageIdentifier && !(_id in path("drafts.**"))][0]{
  slug,
  localizedPage-> {
    ...,
    content[] {
      ...,
      _type == "sectionthirty" => {
        ...,
        "video_ref": video {
          ...,
          asset->{
            ...,
            "url": "https://stream.mux.com/" + playbackId
          }
        },
      },
      _type in ['productReference', 'blogPostReference', 'blogFilterReference'] => {
        'product': *[_type == "product" && _id == ^._ref][0] {
          ...,
          'productImageAsset': productImage.asset-> {metadata},
        },
        'blogPost': *[_type == 'post' && _id == ^._ref][0]{
          'name': author->name,
          'authorImage': author->image,
          'authorBio': author->bio,
          'blogCategory': blogCategory[0]->slug.[$sanityLanguageIdentifier].current,
          'blogCategoryTitle': blogCategory[0]->title.[$sanityLanguageIdentifier],
          'category': categories[0]->slug[$sanityLanguageIdentifier].current,
          'categoryColor': blogCategory[0]->colorlist.value,
          'blogOrDog': categories[0]->title,
          ...,
        },
        'blogFilter': *[_type == 'blogFilter' && _id == ^._ref][0]{
          'blogCategories': blogNavBarCategories[]->,
          header,
        }
      },
      employees[]{
        _type == "reference" => @->
      },
    },
    employees[]{
      _type == "reference" => @->
    },
  }
}`;
export const indexPageQuery = `
  *[_type == "route" && slug[$sanityLanguageIdentifier].current == $slug && !(_id in path("drafts.**"))][0]{
    slug,
    page-> {
      ...,
      content[] {
        _type == "sectionthirty" => {
          ...,
          "video_ref": video {
            ...,
            asset->{
              ...,
              "url": "https://stream.mux.com/" + playbackId
            }
          },
        },
        _type in ['productReference', 'blogPostReference', 'blogFilterReference'] => {
          'product': *[_type == "product" && _id == ^._ref][0] {
            ...,
            'productImageAsset': productImage.asset-> {metadata},
          },
          'blogPost': *[_type == 'post' && _id == ^._ref][0]{
            'name': author->name,
            'authorImage': author->image,
            'authorBio': author->bio,
            'blogCategory': blogCategory[0]->slug.[$sanityLanguageIdentifier].current,
            'blogCategoryTitle': blogCategory[0]->title.[$sanityLanguageIdentifier],
            'category': categories[0]->slug[$sanityLanguageIdentifier].current,
            'categoryColor': blogCategory[0]->colorlist.value,
            'blogOrDog': categories[0]->title,
            ...,
          },
          'blogFilter': *[_type == 'blogFilter' && _id == ^._ref][0]{
            'blogCategories': blogNavBarCategories[]->,
            header,
          }
        },
        employees[]{
          _type == "reference" => @->
        },
        ...
      },
      employees[]{
        _type == "reference" => @->
      },
    }
  }
  `;
export const footerQuery = `*[_type == "footerv2"][0]{
  ...,
  sections[]{
    ...,
    links[]{
      ...,
      url-> {
        ...,
  "groupRoutes": *[_type == "localizedRoute" && groupid == ^.groupid]
    | order(language asc)  
      }
    }
  }
}`;

// export const footerQuery = `*[_type == "footer"][0]{
//   ...,
//     links[]{
//       ...,
//       _type == "reference" => @->{
//         ...,
//         page->{title}
//       }
//     }
// }`;

export const dogBreedsQuery = `*[_type == "post" && categories[0]->slug["en"].current == "dogbreeds"]
{
  "breed": title.[$sanityLanguageIdentifier],
  "slug": slug.no.current
}`;

// export const storeMapperQuery = `*[_type == "storemapper" && !(_id in path("drafts.**"))]{
//   _id,
//   "address": info.address,
//   "city": info.city.[$sanityLanguageIdentifier],
//   "country": info.country.[$sanityLanguageIdentifier],
//   "geopoint": info.geopoint,
//   "name": info.name.[$sanityLanguageIdentifier],
//   "phone": info.phone,
//   "postalCode": info.postalCode,
//   "url": info.url
// }`;

export const newStoreMapperQuery = `*[_type == "simplestoremapper" && !(_id in path("drafts.**"))]{
  _id,
  "address": info.address,
  "city": info.city,
  "country": info.country,
  "geopoint": info.geopoint,
  "name": info.name,
  "phone": info.phone,
  "postalCode": info.postalCode,
  "url": info.url
}`;

export const reviewQuery = `*[_type == "localizedReview" && (language == $sanityLanguageIdentifier)]
| order(ratingScore desc)
{
  _id,
  "id": id,
  "title": title,
  "author": author,
  "rating": stars,
  "image": userPhotos,
  "language": language
}`;

