import { Image as ImageType } from "../../lib/types";
import Image from "next/image";
import { getPositionFromHotspot } from "../../lib/helpers";
import Link from "next/link";

interface Props {
  header: string;
  subText: string;
  imageone: ImageType;
  imagetwo: ImageType;
  imagethree: ImageType;
  imagefour: ImageType;
  imagefive: ImageType;
  urlFor: Function;
  button?: {
    buttonText: string;
    buttonUrl: string;
  };
}
const LocalizedAgriaNew = (props: Props) => {
  const {
    header,
    imagefive,
    imagefour,
    imageone,
    imagethree,
    imagetwo,
    subText,
    urlFor,
    button,
  } = props;
  return (
    <div className="relative isolate">
      {/* <svg
        aria-hidden="true"
        className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
      >
        <defs>
          <pattern
            x="50%"
            y={-1}
            id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
            width={200}
            height={200}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect
          fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
          width="100%"
          height="100%"
          strokeWidth={0}
        />
      </svg>
      <div
        aria-hidden="true"
        className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
      >
        <div
          style={{
            clipPath:
              "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
          }}
          className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
        />
      </div> */}
      <div className="overflow-hidden font-objektiv">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
            <div className="w-full max-w-xl pt-24 md:pt-0 lg:shrink-0 xl:max-w-2xl">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                {header}
              </h3>
              <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                {subText}
              </p>
            </div>
            <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
              <div className="invisible sm:visible ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:pt-36 xl:pt-44">
                <div className="relative w-[174px] h-[270.48px] hidden lg:block">
                  <Image
                    src={urlFor(imagefive).url()}
                    layout="fill"
                    className="aspect-[2/3] w-full rounded-xl object-cover border border-gray-900/10"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
              <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-0">
                <div className="relative w-[176px] h-[264px]">
                  <Image
                    src={urlFor(imagethree).url()}
                    layout="fill"
                    className="aspect-[2/3] w-full rounded-xl object-cover border border-gray-900/10"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div className="relative w-[176px] h-[264px] hidden lg:block">
                  <Image
                    src={urlFor(imagefour).url()}
                    layout="fill"
                    className="aspect-[2/3] w-full rounded-xl object-cover border border-gray-900/10"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
              <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0 lg:hidden">
                <div className="relative w-[176px] h-[264px]">
                  <Image
                    src={urlFor(imageone).url()}
                    layout="fill"
                    className="aspect-[2/3] w-full rounded-xl object-cover border border-gray-900/10"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div className="relative w-[176px] h-[264px] hidden sm:block">
                  <Image
                    src={urlFor(imagetwo).url()}
                    layout="fill"
                    className="aspect-[2/3] w-full rounded-xl object-cover border border-gray-900/10"
                  />
                  <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
            </div>
          </div>
          {button && (
            <div className="flex w-full mt-8 lg:mt:4 justify-center items-center font-default text-base md:text-lg pt-2">
              <Link href={button.buttonUrl}>
                <a target="_blank" rel="noopener noreferrer">
                  <div className="flex text-center items-center justify-center bg-primary-4 rounded-xl h-12 w-full px-8 hover:scale-110 transition-transform">
                    <p className="text-primary-1 ">{button.buttonText}</p>
                  </div>
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocalizedAgriaNew;
