import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { CountryContext, useLocale } from "@lilbit/shared";
import { useContext, useEffect } from "react";
import {
  basicMonthlyPricesOnly,
  basicTwoYearPrices,
  basicYearlyPrices,
  premiumFiveYearPrices,
  premiumTwoYearPrices,
  premiumYearlyPrices,
  familyYearlyPrices,
  familyTwoYearPrices,
  familyFiveYearPrices,
} from "../lib/subscriptionPrices";
import Link from "next/link";
import Stripe from 'stripe';

const features = [
  {
    id: process.env.NEXT_PUBLIC_STRIPE_BASIC_PRODUCT_ID,
    features: [
      {
        no: "GPS-lokasjon",
        en: "GPS location",
        se: "GPS-plats",
        da: "GPS-placering",
        fi: "GPS-sijainti",
        de: "GPS-Standort",
        fr: "Emplacement GPS",
        es: "Ubicación GPS",
      },
      {
        no: "Livesporing",
        en: "Live tracking",
        se: "Live-spårning",
        da: "Live-tracking",
        fi: "Live-seuranta",
        de: "Live-Tracking",
        fr: "Suivi en direct",
        es: "Seguimiento en vivo",
      },
      {
        no: "Lokasjonshistorikk",
        en: "Location history",
        se: "Plats historia",
        da: "Placering historie",
        fi: "Sijaintihistoria",
        de: "Standortverlauf",
        fr: "Historique de localisation",
        es: "Historial de ubicaciones",
      },
      {
        no: "Lyd og lys",
        en: "Sound and light",
        se: "Ljud och ljus",
        da: "Lyd og lys",
        fi: "Ääni ja valo",
        de: "Ton und Licht",
        fr: "Son et lumière",
        es: "Sonido y luz",
      },
      {
        no: "Aktivitetsnivå",
        en: "Activity level",
        se: "Aktivitetsnivå",
        da: "Aktivitetsniveau",
        fi: "Aktiviteettitaso",
        de: "Aktivitätslevel",
        fr: "Niveau d'activité",
        es: "Nivel de actividad",
      },
    ],
    missingFeatures: [
      {
        no: "Intil en ny enhet årlig ved tap/skade",
        en: "Up to one new device annually in case of loss/damage",
        se: "Upp till en ny enhet årligen vid förlust/stöld/skada",
        da: "Op til en ny enhed årligt i tilfælde af tab/skade",
        fi: "Enintään yksi uusi laite vuosittain tapauksessa vahinkoja/varastettu",
        de: "Bis zu einem neuen Gerät jährlich bei Verlust/Diebstahl/Schaden",
        fr: "Jusqu'à un nouvel appareil par an en cas de perte/vol/dommage",
        es: "Hasta un nuevo dispositivo anual en caso de pérdida/robo/daño",
      },
      {
        no: "4 enheter på ett abonnement",
        en: "4 devices on one subscription",
        se: "4 enheter på en prenumeration",
        da: "4 enheder på ét abonnement",
        fi: "4 laitetta yhdellä tilauksella",
        de: "4 Geräte auf einem Abonnement",
        fr: "4 appareils sur un abonnement",
        es: "4 dispositivos en una suscripción"
      }
    ],
  },
  {
    id: process.env.NEXT_PUBLIC_STRIPE_PREMIUM_PRODUCT_ID,
    features: [
      {
        no: "GPS-lokasjon",
        en: "GPS location",
        se: "GPS-plats",
        da: "GPS-placering",
        fi: "GPS-sijainti",
        de: "GPS-Standort",
        fr: "Emplacement GPS",
        es: "Ubicación GPS",
      },
      {
        no: "Livesporing",
        en: "Live tracking",
        se: "Live-spårning",
        da: "Live-tracking",
        fi: "Live-seuranta",
        de: "Live-Tracking",
        fr: "Suivi en direct",
        es: "Seguimiento en vivo",
      },
      {
        no: "Lokasjonshistorikk",
        en: "Location history",
        se: "Plats historia",
        da: "Placering historie",
        fi: "Sijaintihistoria",
        de: "Standortverlauf",
        fr: "Historique de localisation",
        es: "Historial de ubicaciones",
      },
      {
        no: "Lyd og lys",
        en: "Sound and light",
        se: "Ljud och ljus",
        da: "Lyd og lys",
        fi: "Ääni ja valo",
        de: "Ton und Licht",
        fr: "Son et lumière",
        es: "Sonido y luz",
      },
      {
        no: "Aktivitetsnivå",
        en: "Activity level",
        se: "Aktivitetsnivå",
        da: "Aktivitetsniveau",
        fi: "Aktiviteettitaso",
        de: "Aktivitätslevel",
        fr: "Niveau d'activité",
        es: "Nivel de actividad",
      },
      {
        no: "Inntil  en ny enhet årlig ved tap/skade",
        en: "Up to one new device annually in case of loss/damage",
        se: "Upp till en ny enhet årligen vid förlust/stöld/skada",
        da: "Op til en ny enhed årligt i tilfælde af tab/skade",
        fi: "Enintään yksi uusi laite vuosittain tapauksessa vahinkoja/varastettu",
        de: "Bis zu einem neuen Gerät jährlich bei Verlust/Diebstahl/Schaden",
        fr: "Jusqu'à un nouvel appareil par an en cas de perte/vol/dommage",
        es: "Hasta un nuevo dispositivo anual en caso de pérdida/robo/daño",
      },
    ],
    missingFeatures: [
      {
        no: "4 enheter på ett abonnement",
        en: "4 devices on one subscription",
        se: "4 enheter på en prenumeration",
        da: "4 enheder på ét abonnement",
        fi: "4 laitetta yhdellä tilauksella",
        de: "4 Geräte auf einem Abonnement",
        fr: "4 appareils sur un abonnement",
        es: "4 dispositivos en una suscripción"
      }
    ],
  },
  {
    id: process.env.NEXT_PUBLIC_STRIPE_FAMILY_PRODUCT_ID,
    features: [
      {
        no: "GPS-lokasjon",
        en: "GPS location",
        se: "GPS-plats",
        da: "GPS-placering",
        fi: "GPS-sijainti",
        de: "GPS-Standort",
        fr: "Emplacement GPS",
        es: "Ubicación GPS",
      },
      {
        no: "Livesporing",
        en: "Live tracking",
        se: "Live-spårning",
        da: "Live-tracking",
        fi: "Live-seuranta",
        de: "Live-Tracking",
        fr: "Suivi en direct",
        es: "Seguimiento en vivo",
      },
      {
        no: "Lokasjonshistorikk",
        en: "Location history",
        se: "Plats historia",
        da: "Placering historie",
        fi: "Sijaintihistoria",
        de: "Standortverlauf",
        fr: "Historique de localisation",
        es: "Historial de ubicaciones",
      },
      {
        no: "Lyd og lys",
        en: "Sound and light",
        se: "Ljud och ljus",
        da: "Lyd og lys",
        fi: "Ääni ja valo",
        de: "Ton und Licht",
        fr: "Son et lumière",
        es: "Sonido y luz",
      },
      {
        no: "Aktivitetsnivå",
        en: "Activity level",
        se: "Aktivitetsnivå",
        da: "Aktivitetsniveau",
        fi: "Aktiviteettitaso",
        de: "Aktivitätslevel",
        fr: "Niveau d'activité",
        es: "Nivel de actividad",
      },
      {
        no: "Inntil  en ny enhet årlig ved tap/skade",
        en: "Up to one new device annually in case of loss/damage",
        se: "Upp till en ny enhet årligen vid förlust/stöld/skada",
        da: "Op til en ny enhed årligt i tilfælde af tab/skade",
        fi: "Enintään yksi uusi laite vuosittain tapauksessa vahinkoja/varastettu",
        de: "Bis zu einem neuen Gerät jährlich bei Verlust/Diebstahl/Schaden",
        fr: "Jusqu'à un nouvel appareil par an en cas de perte/vol/dommage",
        es: "Hasta un nuevo dispositivo anual en caso de pérdida/robo/daño",
      },
      {
        no: "4 enheter på ett abonnement",
        en: "4 devices on one subscription",
        se: "4 enheter på en prenumeration",
        da: "4 enheder på ét abonnement",
        fi: "4 laitetta yhdellä tilauksella",
        de: "4 Geräte auf einem Abonnement",
        fr: "4 appareils sur un abonnement",
        es: "4 dispositivos en una suscripción"
      }
    ],
    missingFeatures: [],
  },
];

interface Props {
  basicProductWithPrices?: Stripe.Product & {
    prices: Stripe.Response<Stripe.ApiList<Stripe.Price>>;
  };
  premiumProductWithPrices?: Stripe.Product & {
    prices: Stripe.Response<Stripe.ApiList<Stripe.Price>>;
  };
  familyProductWithPrices?: Stripe.Product & {
    prices: Stripe.Response<Stripe.ApiList<Stripe.Price>>;
  };
  forEverCoupon?: Stripe.Coupon;
  showOnFullScreen?: boolean;
}

const PricingTable = ({
  //TODO use prices from Stripe
  basicProductWithPrices,
  premiumProductWithPrices,
  familyProductWithPrices,
  forEverCoupon,
  showOnFullScreen
}: Props) => {
  const urlPrefix = useLocale().urlPrefix;
  const { country } = useContext(CountryContext);

  let discountedBasicMonthlyPricesOnly;
  let discountedBasicTwoYearPrices;
  let discountedBasicYearlyPrices;
  let discountedPremiumFiveYearPrices;
  let discountedPremiumTwoYearPrices;
  let discountedPremiumYearlyPrices;
  let discountedFamilyYearlyPrices;
  let discountedFamilyTwoYearPrices;
  let discountedFamilyFiveYearPrices;

  function applyDiscount(prices, discount) {
    const discounted = {};
    for (const currency in prices) {
      if (currency === "NOK" || currency === "SEK") {
        discounted[currency] = (prices[currency] * (1 - discount / 100)).toFixed(0);
      } else {
        discounted[currency] = (prices[currency] * (1 - discount / 100)).toFixed(2);
      }
    }
    return discounted;
  }

  const isCouponValid = forEverCoupon?.valid;

  if (isCouponValid && forEverCoupon?.percent_off) {
    const discount = forEverCoupon.percent_off;

    discountedBasicMonthlyPricesOnly = applyDiscount(basicMonthlyPricesOnly, discount);
    discountedBasicYearlyPrices = applyDiscount(basicYearlyPrices, discount);
    discountedBasicTwoYearPrices = applyDiscount(basicTwoYearPrices, discount);
    discountedPremiumFiveYearPrices = applyDiscount(premiumFiveYearPrices, discount);
    discountedPremiumTwoYearPrices = applyDiscount(premiumTwoYearPrices, discount);
    discountedPremiumYearlyPrices = applyDiscount(premiumYearlyPrices, discount);
    discountedFamilyYearlyPrices = applyDiscount(familyYearlyPrices, discount);
    discountedFamilyTwoYearPrices = applyDiscount(familyTwoYearPrices, discount);
    discountedFamilyFiveYearPrices = applyDiscount(familyFiveYearPrices, discount);
  }

  const basicMonthlyPrices = {
    id: process.env.NEXT_PUBLIC_STRIPE_BASIC_PRODUCT_ID,
    header: translations.basic,
    description: {
      no: "Abonnement for deg som vil ha kontroll over hundens helse.",
      en: "Subscription for those who want to monitor their dog's health.",
      se: "Prenumeration för dig som vill ha kontroll över din hunds hälsa.",
      da: "Abonnement til dig, der vil have kontrol over din hunds sundhed.",
      fi: "Tilauksesi sinulle, joka haluat pitää koirasi terveydentilan hallinnassa.",
      de: "Abonnement für diejenigen, die die Gesundheit ihres Hundes im Blick behalten möchten.",
      fr: "Abonnement pour ceux qui veulent surveiller la santé de leur chien.",
      es: "Suscripción para quienes quieren controlar la salud de su perro."
    },
    firstInterval: {
      label: {
        en: "Monthly",
        no: "Månedlig",
        se: "Månadsvis",
        da: "Månedligt",
        fi: "Kuukausittain",
        de: "Monatlich",
        fr: "Mensuel",
        es: "Mensual",
      },
      description: {
        en: `Billed monthly with ${isCouponValid ? discountedBasicMonthlyPricesOnly[country.currency] : basicMonthlyPricesOnly[country.currency]}${country.currency === "NOK" ? ",-" : " " + country.currency
          }`,
        no: `Faktureres månedlig med ${isCouponValid ? discountedBasicMonthlyPricesOnly[country.currency] : basicMonthlyPricesOnly[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        se: `Faktureras månadsvis med ${isCouponValid ? discountedBasicMonthlyPricesOnly[country.currency] : basicMonthlyPricesOnly[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        da: `Faktureres månedligt med ${isCouponValid ? discountedBasicMonthlyPricesOnly[country.currency] : basicMonthlyPricesOnly[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        fi: `Laskutetaan kuukausittain ${isCouponValid ? discountedBasicMonthlyPricesOnly[country.currency] : basicMonthlyPricesOnly[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        de: `Monatlich mit ${isCouponValid ? discountedBasicMonthlyPricesOnly[country.currency] : basicMonthlyPricesOnly[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        fr: `Facturé mensuellement avec ${isCouponValid ? discountedBasicMonthlyPricesOnly[country.currency] : basicMonthlyPricesOnly[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        es: `Facturado mensualmente con ${isCouponValid ? discountedBasicMonthlyPricesOnly[country.currency] : basicMonthlyPricesOnly[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
      },
      NOK: isCouponValid ? discountedBasicMonthlyPricesOnly["NOK"] : basicMonthlyPricesOnly["NOK"],
      EUR: isCouponValid ? discountedBasicMonthlyPricesOnly["EUR"] : basicMonthlyPricesOnly["EUR"],
      USD: isCouponValid ? discountedBasicMonthlyPricesOnly["USD"] : basicMonthlyPricesOnly["USD"],
      SEK: isCouponValid ? discountedBasicMonthlyPricesOnly["SEK"] : basicMonthlyPricesOnly["SEK"],
      DKK: isCouponValid ? discountedBasicMonthlyPricesOnly["DKK"] : basicMonthlyPricesOnly["DKK"],
    },
    secondInterval: {
      label: {
        en: "1 year",
        no: "1 år",
        se: "1 år",
        da: "1 år",
        fi: "1 vuosi",
        de: "1 Jahr",
        fr: "1 an",
        es: "1 año",
      },
      description: {
        en: `Billed yearly with ${isCouponValid ? discountedBasicYearlyPrices[country.currency] : basicYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        no: `Faktureres årlig med ${isCouponValid ? discountedBasicYearlyPrices[country.currency] : basicYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        se: `Faktureras årligen med ${isCouponValid ? discountedBasicYearlyPrices[country.currency] : basicYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        da: `Faktureres årligt med ${isCouponValid ? discountedBasicYearlyPrices[country.currency] : basicYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        fi: `Laskutetaan vuosittain ${isCouponValid ? discountedBasicYearlyPrices[country.currency] : basicYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        de: `Jährlich mit ${isCouponValid ? discountedBasicYearlyPrices[country.currency] : basicYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        fr: `Facturé annuellement avec ${isCouponValid ? discountedBasicYearlyPrices[country.currency] : basicYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        es: `Facturado anualmente con ${isCouponValid ? discountedBasicYearlyPrices[country.currency] : basicYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
      },
      NOK: isCouponValid ? (discountedBasicYearlyPrices["NOK"] / 12).toFixed(0) : (basicYearlyPrices["NOK"] / 12).toFixed(0),
      EUR: isCouponValid ? (discountedBasicYearlyPrices["EUR"] / 12).toFixed(2) : (basicYearlyPrices["EUR"] / 12).toFixed(2),
      USD: isCouponValid ? (discountedBasicYearlyPrices["USD"] / 12).toFixed(2) : (basicYearlyPrices["USD"] / 12).toFixed(2),
      SEK: isCouponValid ? (discountedBasicYearlyPrices["SEK"] / 12).toFixed(0) : (basicYearlyPrices["SEK"] / 12).toFixed(0),
      DKK: isCouponValid ? (discountedBasicYearlyPrices["DKK"] / 12).toFixed(2) : (basicYearlyPrices["DKK"] / 12).toFixed(2),
    },
    thirdInterval: {
      label: {
        en: "2 years",
        no: "2 år",
        se: "2 år",
        da: "2 år",
        fi: "2 vuotta",
        de: "2 Jahre",
        fr: "2 ans",
        es: "2 años",
      },
      description: {
        en: `Billed every 2 years with ${isCouponValid ? discountedBasicTwoYearPrices[country.currency] : basicTwoYearPrices[country.currency]}${country.currency === "NOK" ? ",-" : " " + country.currency
          }`,
        no: `Faktureres hvert 2. år med ${isCouponValid ? discountedBasicTwoYearPrices[country.currency] : basicTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        se: `Faktureras var 2: a år med ${isCouponValid ? discountedBasicTwoYearPrices[country.currency] : basicTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        da: `Faktureres hvert 2. år med ${isCouponValid ? discountedBasicTwoYearPrices[country.currency] : basicTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        fi: `Laskutetaan joka 2. vuosi ${isCouponValid ? discountedBasicTwoYearPrices[country.currency] : basicTwoYearPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        de: `Alle 2 Jahre mit ${isCouponValid ? discountedBasicTwoYearPrices[country.currency] : basicTwoYearPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        fr: `Facturé tous les 2 ans avec ${isCouponValid ? discountedBasicTwoYearPrices[country.currency] : basicTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        es: `Facturado cada 2 años con ${isCouponValid ? discountedBasicTwoYearPrices[country.currency] : basicTwoYearPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
      },
      NOK: isCouponValid ? (discountedBasicTwoYearPrices["NOK"] / 24).toFixed(0) : (basicTwoYearPrices["NOK"] / 24).toFixed(0),
      EUR: isCouponValid ? (discountedBasicTwoYearPrices["EUR"] / 24).toFixed(2) : (basicTwoYearPrices["EUR"] / 24).toFixed(2),
      USD: isCouponValid ? (discountedBasicTwoYearPrices["USD"] / 24).toFixed(2) : (basicTwoYearPrices["USD"] / 24).toFixed(2),
      SEK: isCouponValid ? (discountedBasicTwoYearPrices["SEK"] / 24).toFixed(0) : (basicTwoYearPrices["SEK"] / 24).toFixed(0),
      DKK: isCouponValid ? (discountedBasicTwoYearPrices["DKK"] / 24).toFixed(2) : (basicTwoYearPrices["DKK"] / 24).toFixed(2),
    },
  };
  const premiumMonthlyPrices = {
    id: process.env.NEXT_PUBLIC_STRIPE_PREMIUM_PRODUCT_ID,
    header: translations.premium,
    description: {
      no: "Premium abonnement gir deg en ekstra forsikring om uhellet er ute.",
      en: "Premium subscription gives you extra assurance in case of accidents.",
      se: "Premium-prenumeration ger dig extra trygghet om olyckan är framme.",
      da: "Premium-abonnement giver dig ekstra sikkerhed, hvis uheldet er ude.",
      fi: "Premium-tilaus tarjoaa sinulle lisäturvaa, jos sattuu vahinko.",
      de: "Das Premium-Abonnement bietet Ihnen zusätzliche Sicherheit im Falle eines Unfalls.",
      fr: "L'abonnement Premium vous offre une assurance supplémentaire en cas d'accident.",
      es: "La suscripción Premium te brinda seguridad adicional en caso de accidente."
    }
    ,
    firstInterval: {
      label: {
        en: "1 year",
        no: "1 år",
        se: "1 år",
        da: "1 år",
        fi: "1 vuosi",
        de: "1 Jahr",
        fr: "1 an",
        es: "1 año",
      },
      description: {
        en: `Billed yearly with ${isCouponValid ? discountedPremiumYearlyPrices[country.currency] : premiumYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        no: `Faktureres årlig med ${isCouponValid ? discountedPremiumYearlyPrices[country.currency] : premiumYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        se: `Faktureras årligen med ${isCouponValid ? discountedPremiumYearlyPrices[country.currency] : premiumYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        da: `Faktureres årligt med ${isCouponValid ? discountedPremiumYearlyPrices[country.currency] : premiumYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        fi: `Laskutetaan vuosittain ${isCouponValid ? discountedPremiumYearlyPrices[country.currency] : premiumYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        de: `Jährlich mit ${isCouponValid ? discountedPremiumYearlyPrices[country.currency] : premiumYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        fr: `Facturé annuellement avec ${isCouponValid ? discountedPremiumYearlyPrices[country.currency] : premiumYearlyPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        es: `Facturado anualmente con ${isCouponValid ? discountedPremiumYearlyPrices[country.currency] : premiumYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
      },
      NOK: isCouponValid ? (discountedPremiumYearlyPrices["NOK"] / 12).toFixed(0) : (premiumYearlyPrices["NOK"] / 12).toFixed(0),
      EUR: isCouponValid ? (discountedPremiumYearlyPrices["EUR"] / 12).toFixed(2) : (premiumYearlyPrices["EUR"] / 12).toFixed(2),
      USD: isCouponValid ? (discountedPremiumYearlyPrices["USD"] / 12).toFixed(2) : (premiumYearlyPrices["USD"] / 12).toFixed(2),
      SEK: isCouponValid ? (discountedPremiumYearlyPrices["SEK"] / 12).toFixed(0) : (premiumYearlyPrices["SEK"] / 12).toFixed(0),
      DKK: isCouponValid ? (discountedPremiumYearlyPrices["DKK"] / 12).toFixed(2) : (premiumYearlyPrices["DKK"] / 12).toFixed(2),
    },
    secondInterval: {
      label: {
        en: "2 years",
        no: "2 år",
        se: "2 år",
        da: "2 år",
        fi: "2 vuotta",
        de: "2 Jahre",
        fr: "2 ans",
        es: "2 años",
      },
      description: {
        en: `Billed every 2 years with ${isCouponValid ? discountedPremiumTwoYearPrices[country.currency] : premiumTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        no: `Faktureres hvert 2. år med ${isCouponValid ? discountedPremiumTwoYearPrices[country.currency] : premiumTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        se: `Faktureras var 2: e år med ${isCouponValid ? discountedPremiumTwoYearPrices[country.currency] : premiumTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        da: `Faktureres hvert 2. år med ${isCouponValid ? discountedPremiumTwoYearPrices[country.currency] : premiumTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        fi: `Laskutetaan joka 2. vuosi ${isCouponValid ? discountedPremiumTwoYearPrices[country.currency] : premiumTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        de: `Alle 2 Jahre mit ${isCouponValid ? discountedPremiumTwoYearPrices[country.currency] : premiumTwoYearPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        fr: `Facturé tous les 2 ans avec ${isCouponValid ? discountedPremiumTwoYearPrices[country.currency] : premiumTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        es: `Facturado cada 2 años con ${isCouponValid ? discountedPremiumTwoYearPrices[country.currency] : premiumTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
      },
      NOK: isCouponValid ? (discountedPremiumTwoYearPrices["NOK"] / 24).toFixed(0) : (premiumTwoYearPrices["NOK"] / 24).toFixed(0),
      EUR: isCouponValid ? (discountedPremiumTwoYearPrices["EUR"] / 24).toFixed(2) : (premiumTwoYearPrices["EUR"] / 24).toFixed(2),
      USD: isCouponValid ? (discountedPremiumTwoYearPrices["USD"] / 24).toFixed(2) : (premiumTwoYearPrices["USD"] / 24).toFixed(2),
      SEK: isCouponValid ? (discountedPremiumTwoYearPrices["SEK"] / 24).toFixed(0) : (premiumTwoYearPrices["SEK"] / 24).toFixed(0),
      DKK: isCouponValid ? (discountedPremiumTwoYearPrices["DKK"] / 24).toFixed(2) : (premiumTwoYearPrices["DKK"] / 24).toFixed(2),
    },
    thirdInterval: {
      label: {
        en: "5 years",
        no: "5 år",
        se: "5 år",
        da: "5 år",
        fi: "5 vuotta",
        de: "5 Jahre",
        fr: "5 ans",
        es: "5 años",
      },
      description: {
        en: `Billed every 5 years with ${isCouponValid ? discountedPremiumFiveYearPrices[country.currency] : premiumFiveYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        no: `Faktureres hvert 5. år med ${isCouponValid ? discountedPremiumFiveYearPrices[country.currency] : premiumFiveYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        se: `Faktureras var 5: e år med ${isCouponValid ? discountedPremiumFiveYearPrices[country.currency] : premiumFiveYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        da: `Faktureres hvert 5. år med ${isCouponValid ? discountedPremiumFiveYearPrices[country.currency] : premiumFiveYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        fi: `Laskutetaan joka 5. vuosi ${isCouponValid ? discountedPremiumFiveYearPrices[country.currency] : premiumFiveYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        de: `Alle 5 Jahre mit ${isCouponValid ? discountedPremiumFiveYearPrices[country.currency] : premiumFiveYearPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        fr: `Facturé tous les 5 ans avec ${isCouponValid ? discountedPremiumFiveYearPrices[country.currency] : premiumFiveYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        es: `Facturado cada 5 años con ${isCouponValid ? discountedPremiumFiveYearPrices[country.currency] : premiumFiveYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
      },
      NOK: isCouponValid ? (discountedPremiumFiveYearPrices["NOK"] / 60).toFixed(0) : (premiumFiveYearPrices["NOK"] / 60).toFixed(0),
      EUR: isCouponValid ? (discountedPremiumFiveYearPrices["EUR"] / 60).toFixed(2) : (premiumFiveYearPrices["EUR"] / 60).toFixed(2),
      USD: isCouponValid ? (discountedPremiumFiveYearPrices["USD"] / 60).toFixed(2) : (premiumFiveYearPrices["USD"] / 60).toFixed(2),
      SEK: isCouponValid ? (discountedPremiumFiveYearPrices["SEK"] / 60).toFixed(0) : (premiumFiveYearPrices["SEK"] / 60).toFixed(0),
      DKK: isCouponValid ? (discountedPremiumFiveYearPrices["DKK"] / 60).toFixed(2) : (premiumFiveYearPrices["DKK"] / 60).toFixed(2),
    },
  };
  const familyMonthlyPrices = {
    id: process.env.NEXT_PUBLIC_STRIPE_FAMILY_PRODUCT_ID,
    header: translations.family,
    description: {
      no: "For deg som har mer enn en hund eller katt i familien. \nVerdens første og eneste familieabonnement til kjæledyr.",
      en: "For those who have more than one dog or cat in the family. \nThe world's first and only family subscription for pets.",
      se: "För dig som har mer än en hund eller katt i familjen. \nVärldens första och enda familjeprenumeration för husdjur.",
      da: "For dig, der har mere end en hund eller kat i familien. \nVerdens første og eneste familieabonnement til kæledyr.",
      fi: "Sinulle, jolla on enemmän kuin yksi koira tai kissa perheessä. \nMaailman ensimmäinen ja ainoa perhetilaus lemmikeille.",
      de: "Für diejenigen, die mehr als einen Hund oder eine Katze in der Familie haben. \nDas weltweit erste und einzige Familienabonnement für Haustiere.",
      fr: "Pour ceux qui ont plus d'un chien ou un chat dans la famille. \nLe premier et unique abonnement familial pour animaux de compagnie au monde.",
      es: "Para aquellos que tienen más de un perro o gato en la familia. \nEl primer y único abono familiar para mascotas del mundo."
    },
    firstInterval: {
      label: {
        en: "1 year",
        no: "1 år",
        se: "1 år",
        da: "1 år",
        fi: "1 vuosi",
        de: "1 Jahr",
        fr: "1 an",
        es: "1 año",
      },
      description: {
        en: `Billed yearly with ${isCouponValid ? discountedFamilyYearlyPrices[country.currency] : familyYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        no: `Faktureres årlig med ${isCouponValid ? discountedFamilyYearlyPrices[country.currency] : familyYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        se: `Faktureras årligen med ${isCouponValid ? discountedFamilyYearlyPrices[country.currency] : familyYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        da: `Faktureres årligt med ${isCouponValid ? discountedFamilyYearlyPrices[country.currency] : familyYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        fi: `Laskutetaan vuosittain ${isCouponValid ? discountedFamilyYearlyPrices[country.currency] : familyYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        de: `Jährlich mit ${isCouponValid ? discountedFamilyYearlyPrices[country.currency] : familyYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        fr: `Facturé annuellement avec ${isCouponValid ? discountedFamilyYearlyPrices[country.currency] : familyYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        es: `Facturado anualmente con ${isCouponValid ? discountedFamilyYearlyPrices[country.currency] : familyYearlyPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
      },
      NOK: isCouponValid ? (discountedFamilyYearlyPrices["NOK"] / 12 / 4).toFixed(0) : (familyYearlyPrices["NOK"] / 12).toFixed(0),
      EUR: isCouponValid ? (discountedFamilyYearlyPrices["EUR"] / 12 / 4).toFixed(2) : (familyYearlyPrices["EUR"] / 12).toFixed(2),
      USD: isCouponValid ? (discountedFamilyYearlyPrices["USD"] / 12 / 4).toFixed(2) : (familyYearlyPrices["USD"] / 12).toFixed(2),
      SEK: isCouponValid ? (discountedFamilyYearlyPrices["SEK"] / 12 / 4).toFixed(0) : (familyYearlyPrices["SEK"] / 12).toFixed(0),
      DKK: isCouponValid ? (discountedFamilyYearlyPrices["DKK"] / 12 / 4).toFixed(2) : (familyYearlyPrices["DKK"] / 12).toFixed(2),
    },
    secondInterval: {
      label: {
        en: "2 years",
        no: "2 år",
        se: "2 år",
        da: "2 år",
        fi: "2 vuotta",
        de: "2 Jahre",
        fr: "2 ans",
        es: "2 años",
      },
      description: {
        en: `Billed every 2 years with ${isCouponValid ? discountedFamilyTwoYearPrices[country.currency] : familyTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        no: `Faktureres hvert 2. år med ${isCouponValid ? discountedFamilyTwoYearPrices[country.currency] : familyTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        se: `Faktureras var 2: e år med ${isCouponValid ? discountedFamilyTwoYearPrices[country.currency] : familyTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        da: `Faktureres hvert 2. år med ${isCouponValid ? discountedFamilyTwoYearPrices[country.currency] : familyTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        fi: `Laskutetaan joka 2. vuosi ${isCouponValid ? discountedFamilyTwoYearPrices[country.currency] : familyTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        de: `Alle 2 Jahre mit ${isCouponValid ? discountedFamilyTwoYearPrices[country.currency] : familyTwoYearPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        fr: `Facturé tous les 2 ans avec ${isCouponValid ? discountedFamilyTwoYearPrices[country.currency] : familyTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        es: `Facturado cada 2 años con ${isCouponValid ? discountedFamilyTwoYearPrices[country.currency] : familyTwoYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
      },
      NOK: isCouponValid ? (discountedFamilyTwoYearPrices["NOK"] / 24 / 4).toFixed(0) : (familyTwoYearPrices["NOK"] / 24).toFixed(0),
      EUR: isCouponValid ? (discountedFamilyTwoYearPrices["EUR"] / 24 / 4).toFixed(2) : (familyTwoYearPrices["EUR"] / 24).toFixed(2),
      USD: isCouponValid ? (discountedFamilyTwoYearPrices["USD"] / 24 / 4).toFixed(2) : (familyTwoYearPrices["USD"] / 24).toFixed(2),
      SEK: isCouponValid ? (discountedFamilyTwoYearPrices["SEK"] / 24 / 4).toFixed(0) : (familyTwoYearPrices["SEK"] / 24).toFixed(0),
      DKK: isCouponValid ? (discountedFamilyTwoYearPrices["DKK"] / 24 / 4).toFixed(2) : (familyTwoYearPrices["DKK"] / 24).toFixed(2),
    },
    thirdInterval: {
      label: {
        en: "5 years",
        no: "5 år",
        se: "5 år",
        da: "5 år",
        fi: "5 vuotta",
        de: "5 Jahre",
        fr: "5 ans",
        es: "5 años",
      },
      description: {
        en: `Billed every 5 years with ${isCouponValid ? discountedFamilyFiveYearPrices[country.currency] : familyFiveYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        no: `Faktureres hvert 5. år med ${isCouponValid ? discountedFamilyFiveYearPrices[country.currency] : familyFiveYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        se: `Faktureras var 5: e år med ${isCouponValid ? discountedFamilyFiveYearPrices[country.currency] : familyFiveYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        da: `Faktureres hvert 5. år med ${isCouponValid ? discountedFamilyFiveYearPrices[country.currency] : familyFiveYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        fi: `Laskutetaan joka 5. vuosi ${isCouponValid ? discountedFamilyFiveYearPrices[country.currency] : familyFiveYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        de: `Alle 5 Jahre mit ${isCouponValid ? discountedFamilyFiveYearPrices[country.currency] : familyFiveYearPrices[country.currency]}${country.currency === "NOK" || country.currency == "SEK"
          ? ",-"
          : " " + country.currency
          }`,
        fr: `Facturé tous les 5 ans avec ${isCouponValid ? discountedFamilyFiveYearPrices[country.currency] : familyFiveYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
        es: `Facturado cada 5 años con ${isCouponValid ? discountedFamilyFiveYearPrices[country.currency] : familyFiveYearPrices[country.currency]
          }${country.currency === "NOK" || country.currency == "SEK"
            ? ",-"
            : " " + country.currency
          }`,
      },
      NOK: isCouponValid ? (discountedFamilyFiveYearPrices["NOK"] / 60 / 4).toFixed(0) : (familyFiveYearPrices["NOK"] / 60).toFixed(0),
      EUR: isCouponValid ? (discountedFamilyFiveYearPrices["EUR"] / 60 / 4).toFixed(2) : (familyFiveYearPrices["EUR"] / 60).toFixed(2),
      USD: isCouponValid ? (discountedFamilyFiveYearPrices["USD"] / 60 / 4).toFixed(2) : (familyFiveYearPrices["USD"] / 60).toFixed(2),
      SEK: isCouponValid ? (discountedFamilyFiveYearPrices["SEK"] / 60 / 4).toFixed(0) : (familyFiveYearPrices["SEK"] / 60).toFixed(0),
      DKK: isCouponValid ? (discountedFamilyFiveYearPrices["DKK"] / 60 / 4).toFixed(2) : (familyFiveYearPrices["DKK"] / 60).toFixed(2),
    },
  };
  return (
    <div className={`w-full flex flex-col space-y-4 mt-4 px-2 font-objektiv ${showOnFullScreen ? 'md:grid md:grid-cols-3 md:gap-4 md:space-y-0' : 'space-y-10'}`}>
      {[basicMonthlyPrices, premiumMonthlyPrices, familyMonthlyPrices].map(
        (price) => (
          <div
            className="h-full bg-white w-full relative rounded-3xl shadow-lg hover:shadow-2xl transition-all duration-300 flex flex-col"
            key={price.id}
          >
            {isCouponValid && (
              <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-xs font-bold px-4 py-1 shadow-md rounded-lg">
                {translations.save[urlPrefix]} {forEverCoupon.percent_off}%
              </div>
            )}
            <div className="rounded-3xl ring-1 ring-gray-200 hover:ring-primary-8 transition-all duration-300 flex flex-col h-full">
              <div className="p-8 flex-1">
                <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                  {price.header[urlPrefix]}
                </h3>
                <h4 className="text-md font-normal tracking-tight text-gray-900 opacity-75 my-5">
                  {price.description[urlPrefix]}
                </h4>
                <div className="mt-2 flex items-center gap-x-2">
                  <h4 className="flex-none text-xs font-semibold text-primary-2">
                    {translations.whatsIncluded[urlPrefix]}
                  </h4>
                  <div className="h-px flex-auto bg-gray-100" />
                </div>
                <ul
                  role="list"
                  className="mt-2 grid grid-cols-1 gap-2 text-xs leading-6 text-secondary-6"
                >
                  {features
                    .find((f) => f.id === price.id)
                    .features.map((feature) => (
                      <li
                        key={feature[urlPrefix]}
                        className="flex gap-x-3 items-center text-primary-mono-2"
                      >
                        <CheckIcon
                          className="h-4 w-4 flex-none"
                          aria-hidden="true"
                        />
                        {feature[urlPrefix]}
                      </li>
                    ))}
                  {features
                    .find((f) => f.id === price.id)
                    .missingFeatures.map((feature) => (
                      <li
                        key={feature[urlPrefix]}
                        className="flex gap-x-3 line-through items-center text-primary-mono-2"
                      >
                        <XMarkIcon
                          className="h-4 w-4 flex-none"
                          aria-hidden="true"
                        />
                        {feature[urlPrefix]}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="grid lg:grid-cols-3 lg:gap-4 px-4 pb-6">
                {['thirdInterval', 'secondInterval', 'firstInterval'].map(
                  (interval) => (
                    <div
                      key={interval}
                      className="flex flex-col border-b-[0.5px] border-gray-200 pb-4 relative mt-4 lg:border-b-0 lg:pb-0"
                    >
                      <span className="absolute inline-flex items-center rounded-md bg-primary-4 px-2 py-1 -top-2 text-xs font-medium text-primary-1 ring-1 ring-inset ring-primary-2/20">
                        {price[interval].label[urlPrefix]}
                      </span>
                      <div className="mt-6 flex items-baseline gap-x-2">
                        <div className="flex flex-col items-start">
                          <div className="flex items-center lg:items-start lg:flex-col">
                            <span className="text-lg font-bold tracking-tight text-gray-900">
                              {price[interval][country.currency]}
                              {country.currency === 'NOK'
                                ? ',-'
                                : ' ' + country.currency}
                            </span>
                            <span className="text-xs font-semibold leading-6 tracking-wide text-gray-600">
                              <span className="lg:hidden">&nbsp;</span>
                              {price.id ===
                                process.env.NEXT_PUBLIC_STRIPE_FAMILY_PRODUCT_ID
                                ? translations.priceSuffixFamily[urlPrefix]
                                : translations.priceSuffix[urlPrefix]}
                            </span>
                          </div>
                        </div>
                      </div>
                      <p className="text-[11px] text-gray-600">
                        {price[interval].description[urlPrefix]}
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

const translations = {
  family: {
    en: "Family",
    no: "Familie",
    se: "Familj",
    da: "Familie",
    fi: "Perhe",
    de: "Familie",
    fr: "Famille",
    es: "Familia",
  },
  premium: {
    en: "Premium",
    no: "Premium",
    se: "Premium",
    da: "Premium",
    fi: "Premium",
    de: "Premium",
    fr: "Premium",
    es: "Premium",
  },
  basic: {
    en: "Basic",
    no: "Basic",
    se: "Basic",
    da: "Basic",
    fi: "Basic",
    de: "Basic",
    fr: "Basic",
    es: "Basic",
  },
  whatsIncluded: {
    en: "What’s included",
    no: "Hva er inkludert",
    se: "Vad ingår",
    da: "Hvad er inkluderet",
    fi: "Mitä sisältyy",
    de: "Was ist enthalten",
    fr: "Ce qui est inclus",
    es: "Qué está incluido",
  },
  priceFrom: {
    en: "from",
    no: "fra",
    se: "från",
    da: "fra",
    fi: "alkaen",
    de: "von",
    fr: "de",
    es: "de",
  },
  priceSuffix: {
    en: "/month",
    no: "/mnd",
    se: "/månad",
    da: "/måned",
    fi: "/kuukausi",
    de: "/Monat",
    fr: "/mois",
    es: "/mes",
  },
  priceSuffixFamily: {
    no: "pr enh/mnd",
    en: "pr unit/month",
    se: "pr enhet/månad",
    da: "pr enhed/måned",
    fi: "pr laite/kuukausi",
    de: "pr Einheit/Monat",
    fr: "pr unité/mois",
    es: "pr unidad/mes"
  },
  readMore: {
    en: "Read more",
    no: "Les mer",
    se: "Läs mer",
    da: "Læs mere",
    fi: "Lue lisää",
    de: "Weiterlesen",
    fr: "Lire la suite",
    es: "Lee mas",
  },
  subscription: {
    no: "/no/abonnement",
    en: "/en/subscriptions",
    se: "/se/abonnemang",
    da: "/da/abonnementer",
    fi: "/fi/tilaukset",
    de: "/de/abonnements",
    fr: "/fr/abonnements",
    es: "/es/suscripciones",
  },
  save: {
    en: "Save",
    no: "Spar",
    se: "Spara",
    da: "Spar",
    fi: "Säästä",
    de: "Sparen",
    fr: "Économisez",
    es: "Ahorra",
  },
};

export default PricingTable;
