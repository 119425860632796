import React from "react";

interface Props {
  rating?: number;
  color?: { filled: string; unfilled: string };
  textColor?: string;
  numberOfReviewers?: number;
}

const ReviewStars = ({
  rating = 5,
  textColor = "text-primary-1",
  color = {
    filled: "#f5eb3b",
    unfilled: "#DCDCDC",
  },
  numberOfReviewers,
}: Props) => {
  const totalReviewStars = 5;
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  const fractionalFill = rating % 1;

  const renderStar = (isFull: boolean, fillPercentage: number) => {
    const points =
      "12 2 15.09 8.09 22 9 17 14 18.91 21 12 17 5.09 21 7 14 2 9 8.91 8.09 12 2";

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        width="32"
        height="32"
        fill={color.unfilled}
      >
        <polygon points={points} />
        {isFull && fillPercentage > 0 && (
          <polygon
            points={points}
            fill={color.filled}
            style={{ clipPath: `inset(0 ${100 - fillPercentage}% 0 0)` }}
          />
        )}
      </svg>
    );
  };

  return (
    <div className="flex flex-row">
      <div className="flex flex-row">
        {[...Array(fullStars)].map((_, index) => (
          <span key={index}>{renderStar(true, 100)}</span>
        ))}
        {hasHalfStar && <span>{renderStar(true, fractionalFill * 100)}</span>}
        {[...Array(totalReviewStars - fullStars - (hasHalfStar ? 1 : 0))].map(
          (_, index) => (
            <span key={index}>{renderStar(false, 0)}</span>
          )
        )}
      </div>
      <span className={`ml-2 text-xl font-p22 font-objektiv ${textColor}`}>
        {rating.toFixed(1)}/{totalReviewStars}
      </span>
    </div>
  );
};

export default ReviewStars;
