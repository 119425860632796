import AddToCart from "../Button/addToCartBtn";
import { GlobalConfig, Product } from "../../lib/types";

interface Props {
  mainProduct: Product;
  config: GlobalConfig;
  header: string;
  secondaryText: string;
  buyButtonText: string;
}
const LocalizedTowTextsAndOneButton = (props: Props) => {
  const { header, secondaryText, buyButtonText, mainProduct, config } = props;
  return (
    <div className="flex flex-col space-y-4 mb-4 mt-2 items-center px-4 font-main">
      {header && (
        <p className="font-bold text-center text-2xl lg:text-4xl">{header}</p>
      )}
      {header && (
        <p className="font-normal font-objectiv text-sm text-center max-w-3xl lg:text-base">
          {secondaryText}
        </p>
      )}
      {buyButtonText && (
        <div className="max-w-xl">
          <AddToCart
            config={config}
            textColor="text-secondary-5"
            borderColor="border-white"
            mainProduct={mainProduct}
            buyBtnText={buyButtonText}
          />
        </div>
      )}
    </div>
  );
};

export default LocalizedTowTextsAndOneButton;
