import AddToCart from "../Button/addToCartBtn";
import ReviewStars from "../reviewStars";
import { GlobalConfig, Image as ImageType } from "../../lib/types";
import { Product } from "../../lib";

interface Props {
  text: string;
  reviews: any;
  buyButtonText?: string;
  config: GlobalConfig;
  mainProduct: Product;
  siteName: "lildog" | "lilcat";
}

const LocalizedSoldDevices = (props: Props) => {
  const {
    text,
    reviews,
    buyButtonText,
    config,
    mainProduct,
  } = props;
  return (
    <div className="mt-4 mb-2 flex flex-col md:flex-row md:mb-0 items-center justify-around">
      {buyButtonText && (
        <div className="my-6 md:my-0 w-full max-w-xs grow">
          <AddToCart
            config={config}
            mainProduct={mainProduct}
            buyBtnText={buyButtonText}
          />
        </div>
      )}
      {reviews && (
        <div className="grow-0">
          <ReviewStars rating={reviews?.totalAverage} numberOfReviewers={reviews?.data?.length} />
        </div>
      )}
      {text && <span className="text-primary-mono-1 text-center text-xl grow-0">{text}</span>}
    </div>
  );
};

export default LocalizedSoldDevices;
