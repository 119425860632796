import React, { useState } from "react";
import { useContext } from "react";
import {
  useLocale,
  Product,
  CartContext,
  GlobalConfig,
} from "@lilbit/shared";
import Link from "next/link";
import VisaLogo from "../svgs/VisaLogo";
import Mastercard from "../svgs/Mastercard";
import AmericanExpressLogo from "../svgs/AmericanExpressLogo";

type Props = {
  config: GlobalConfig;
  mainProduct: Product;
  buyBtnText?: string;
  siteName?: string;
};

const AddToCartWithCardLogos = ({
  mainProduct,
  buyBtnText,
  config,
}: Props) => {
  const locale = useLocale().urlPrefix;
  const { addProduct } = useContext(CartContext);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    addProduct(mainProduct);
    setLoading(false);
  };

  return (
    <Link
      href={{
        pathname: "/[lang]/[category]",
      }}
      as={`/${locale}/${config?.checkoutSlug?.current || "checkout"}`}
    >
      <button
        className={`flex bg-white justify-center items-center font-objektiv cursor-pointer transform transition-colors button-shadow lg:mx-0 border rounded-[40px] h-[48px] w-full focus:outline-none border-secondary-2`}
        onClick={handleClick}
        disabled={loading}
      >
        <div className="flex items-center justify-center w-full space-x-4 px-2 md:px-4">
          <p
            className={`text-p-md leading-6 font-semibold text-center text-primary-mono-4`}
          >
            {buyBtnText || ""}
          </p>
          <VisaLogo width={35} height={25} />
          <Mastercard width={35} height={25} />
          <AmericanExpressLogo width={35} height={25} />
        </div>
      </button>
    </Link>
  );
};

export default AddToCartWithCardLogos;
