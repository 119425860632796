import Image from "next/image";
import { Image as ImageType } from "../../lib/types";
import { getPositionFromHotspot } from "../../lib/helpers";
import { useRouter } from "next/router";
import { useLocale } from "../../translations";
import { localizedExternalPurchaseUrls } from "../NavBar";

interface Props {
  boldText: string;
  smallText: string;
  descriptionText: string;
  icons: Array<{ text: string; image: ImageType; _key: string; _type: string }>;
  mainImage: ImageType;
  appImage: ImageType;
  urlFor: Function;
  siteName: "lildog" | "lilcat";
}

const LocalizedNewHeader = (props: Props) => {
  const {
    boldText,
    smallText,
    descriptionText,
    icons,
    mainImage,
    appImage,
    urlFor,
    siteName,
  } = props;
  const router = useRouter();
  const isLandingPageB = router.query?.utm_lp === "lp2";
  const locale = useLocale().urlPrefix;
  const redirectUrl = localizedExternalPurchaseUrls[siteName]?.[locale];
  const shouldRedirect = Boolean(redirectUrl);

  return (
    <div
      className={`w-full flex flex-col justify-center ${
        isLandingPageB && "mb-8 sm:mb-16"
      }`}
    >
      <div className="relative w-full flex items-center justify-center rounded-3xl h-[600px]">
        <Image
          src={urlFor(mainImage, true).quality(30).url()}
          layout="fill"
          objectFit="cover"
          priority={true}
          alt={mainImage?.alt || ""}
          objectPosition={
            mainImage?.hotspot && getPositionFromHotspot(mainImage.hotspot)
          }
        />
        <div className="absolute top-0 left-0 w-full h-full bg-green-900 opacity-10 z-10"></div>
        <div className="absolute w-full h-[600px] " />
        {appImage && (
          <div className="absolute hidden lg:flex lg:h-[500px] lg:w-[300px] lg:left-4 bottom-8 xl:bottom-10 z-30">
            <Image
              src={urlFor(appImage, true).quality(30).url()}
              layout="fill"
              objectFit="contain"
              priority={true}
              alt={appImage?.alt || ""}
              objectPosition={
                appImage?.hotspot && getPositionFromHotspot(appImage.hotspot)
              }
            />
          </div>
        )}
        <div className={`flex ${appImage? "lg:ml-16" : ""} justify-center relative max-w-6xl w-full px-4 xl:px-0 z-20 pb-8`}>
          <div className="flex flex-col w-full items-center lg:items-center">
            <div className="items-center flex flex-col justify-center translate-y-10 ">
              <h2 className="text-white font-normal text-center text-2xl leading-[28px] max-w-2xl pb-6">
                {smallText || ""}
              </h2>
              <h1 className="text-white font-p22 font-bold text-center leading-[40px] pb-6 text-5xl max-w-2xl">
                {boldText || ""}
              </h1>
              <h3 className="text-white font-p22 text-center leading-[36px] text-3xl max-w-2xl">
                {descriptionText || ""}
              </h3>
              <div className="items-center flex flex-row justify-evenly mt-6 space-x-12">
                {icons?.map((item) => {
                  const { text, image, _key } = item;
                  return (
                    <div key={_key}>
                      <div className="relative">
                        <Image
                          src={urlFor(image, true).url()}
                          height={50}
                          width={80}
                          alt={image.alt || ""}
                        />
                      </div>
                      {text && (
                        <p className="text-white pb-2 text-[15px] leading-[24px] text-center font-objektiv">
                          {text}
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalizedNewHeader;
