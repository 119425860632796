import { useContext } from "react";
import Image from "next/image";
import { CartContext, CountryContext } from "../../context";
import { CountryContextType } from "../../context/types";
import {
  getPositionFromHotspot,
  GlobalConfig,
  Image as ImageType,
  LildogProductPrices,
  LocaleType,
  Product,
} from "../../lib";
import appConfig from "../../lib/app-config";
import Lildogswitcher from "../lildogswitcher";
import AddToCart from "../Button/addToCartBtn";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import PricingTable from "../pricingTable";
import Stripe from 'stripe';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  image: ImageType;
  logoImage: ImageType;
  vippsBtnText?: string;
  paymentIcons: ImageType;
  buyBtnText: string;
  textUnderButton: string;
  bottomText: string;
  technicalData: {
    header: string;
    tableTitle: string;
    specifications: Array<{
      leftColumn: string;
      rightColumn: any;
      _key: string;
    }>;
    bigImag: ImageType;
    images: Array<ImageType & { text: string; _key: string }>;
    icons: Array<{ text: string; image: ImageType; _key: string }>;
  };
  mainProduct: Product;
  mainProductPrices: LildogProductPrices;
  locale: LocaleType;
  productTaxRate: number;
  products: Product[];
  siteName: "lildog" | "lilcat";
  urlFor: Function;
  config: GlobalConfig;
  SimpleBlockContent: any;
  basicProductWithPrices?: Stripe.Product & {
    prices: Stripe.Response<Stripe.ApiList<Stripe.Price>>;
  };
  premiumProductWithPrices?: Stripe.Product & {
    prices: Stripe.Response<Stripe.ApiList<Stripe.Price>>;
  };
  familyProductWithPrices?: Stripe.Product & {
    prices: Stripe.Response<Stripe.ApiList<Stripe.Price>>;
  };
  forEverCoupon?: Stripe.Coupon;
}
const LocalizedImageAndProduct = (props: Props) => {
  const {
    image,
    logoImage,
    textUnderButton,
    paymentIcons,
    urlFor,
    mainProductPrices,
    mainProduct,
    buyBtnText,
    bottomText,
    SimpleBlockContent,
    technicalData,
    productTaxRate,
    config,
    basicProductWithPrices,
    premiumProductWithPrices,
    familyProductWithPrices,
    forEverCoupon,
  } = props;
  const countryContext: CountryContextType = useContext(CountryContext);
  const { nrOfMainProducts } = useContext(CartContext);
  const { country } = countryContext;

  return (
    <div className="bg-white py-4 sm:py-4 font-objektiv max-w-[1250px] text-primary-mono-1 mx-auto">
      <div className="max-w-2xl mx-auto lg:max-w-none">
        <div className="lg:grid lg:grid-cols-2 lg:items-start">
          <div className="flex flex-col-reverse pt-24">
            <div
              className={`relative translate-y-6 flex w-auto h-[180px] xs:h-[200px] rounded-3xl rounded-bl-none`}
            >
              <Image
                src={urlFor(image, true).url()}
                alt={image.alt || ""}
                priority
                layout="fill"
                objectFit="contain"
                className="rounded-3xl rounded-bl-none"
                objectPosition={
                  image?.hotspot && getPositionFromHotspot(image.hotspot)
                }
              />
            </div>
          </div>

          {/* Product info */}
          <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-24 lg:pl-8">
            <div className="relative w-full flex justify-between">
              <div className="relative w-[120px] h-[45px]">
                <Image
                  src={urlFor(logoImage, true).url()}
                  layout="fill"
                  objectFit="contain"
                  priority={true}
                  alt={logoImage.alt ? logoImage.alt : ""}
                />
              </div>
              <div className="mt-3 pb-6">
                <h3 className="sr-only">Product information</h3>
                <span className="flex space-x-4 pr-8">
                  {mainProductPrices?.ORIGINAL_PRICE && (
                    <span className="line-through text-2xl">
                      {country.currency === "NOK" ? " kr" : country.currency}{" "}
                      {Math.ceil(
                        parseInt(
                          mainProductPrices?.ORIGINAL_PRICE[country.currency]
                        ) +
                          (mainProductPrices?.ORIGINAL_PRICE[country.currency] *
                            productTaxRate) /
                            100
                      )}
                    </span>
                  )}
                  <p className="text-2xl text-primary-mono-1 whitespace-nowrap">
                    {countryContext.country.currency === "NOK"
                      ? " kr"
                      : countryContext.country.currency}{" "}
                    {productTaxRate
                      ? Math.ceil(
                          parseInt(
                            mainProductPrices?.PRICE[
                              countryContext.country.currency
                            ]
                          ) +
                            (mainProductPrices?.PRICE[
                              countryContext.country.currency
                            ] *
                              productTaxRate) /
                              100
                        ) * nrOfMainProducts
                      : Math.ceil(
                          parseInt(
                            mainProductPrices?.PRICE[
                              countryContext.country.currency
                            ]
                          ) +
                            (mainProductPrices?.PRICE[
                              countryContext.country.currency
                            ] *
                              25) /
                              100
                        ) * nrOfMainProducts}{" "}
                    {appConfig.currencySymbols[countryContext.country.currency]}
                  </p>
                </span>
              </div>
            </div>
            {bottomText && (
              <Disclosure as="div" className="">
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`
                          ${open ? "" : "text-opacity-90"}
                          group inline-flex items-center rounded-md bg-primary-mono-4 px-3 py-2 text-base font-semibold text-primary-mono-1 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                      <span>{bottomText}</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "-rotate-180" : "rotate-0 text-opacity-70"
                        }
                            ml-2 h-5 w-5 text-primary-mono-1 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                        aria-hidden="true"
                      />
                    </Disclosure.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      {technicalData && (
                        <Disclosure.Panel
                          className="text-primary-mono-1
                            mt-3 w-full -translate-x-0 transform"
                        >
                          <div>
                            <div className="col-span-3">
                              {technicalData?.header && logoImage && (
                                <div className="bg-primary-mono-4 w-full py-4 px-2 sm:px-8">
                                  <div className="relative w-[120px] h-[45px]">
                                    <Image
                                      src={urlFor(logoImage, true).url()}
                                      layout="fill"
                                      objectFit="contain"
                                      priority={true}
                                      alt={logoImage.alt ? logoImage.alt : ""}
                                    />
                                  </div>
                                  <h3 className="text-base font-semibold leading-7 lg:w-1/2">
                                    {technicalData?.header || ""}
                                  </h3>
                                </div>
                              )}
                              <div className="mt-6 border-primary-mono-3">
                                <dl className="divide-y divide-primary-mono-3">
                                  {technicalData.specifications.map((row) => {
                                    return (
                                      <div
                                        key={row._key}
                                        className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                                      >
                                        <dt className="text-sm font-bold leading-6 ">
                                          {row.leftColumn}
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6  sm:col-span-2 sm:mt-0">
                                          <SimpleBlockContent
                                            blocks={row.rightColumn}
                                          />
                                        </dd>
                                      </div>
                                    );
                                  })}
                                </dl>
                              </div>
                            </div>
                            <div className="col-span-2 flex flex-row"></div>
                          </div>
                          <div className="relative flex flex-row justify-between bg-primary-mono-4 m-4 rounded-xl">
                            {technicalData.icons?.map((item) => {
                              const { text, image, _key } = item;
                              return (
                                <div key={_key} className="p-2">
                                  <div className="flex sm:hidden justify-center">
                                    <Image
                                      src={urlFor(image, true).url()}
                                      height={30}
                                      width={30}
                                      alt={text || ""}
                                    />
                                  </div>
                                  <div className="hidden sm:flex md:justify-center">
                                    <Image
                                      src={urlFor(image, true).url()}
                                      height={40}
                                      width={40}
                                      alt={text || ""}
                                    />
                                  </div>
                                  <div className="text-xs text-center sm:text-sm">
                                    {text}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Disclosure.Panel>
                      )}
                    </Transition>
                  </>
                )}
              </Disclosure>
            )}

            {mainProduct?.stock_status === "instock" && (
              <div className="mt-6">
                <div className="flex space-y-0 sm:space-x-4">
                  <div className="grow">
                    <AddToCart
                      config={config}
                      mainProduct={mainProduct}
                      buyBtnText={buyBtnText}
                      borderWidth={0}
                    />
                  </div>
                  <div className="hidden sm:flex">
                    <Lildogswitcher />
                  </div>
                </div>
                <Disclosure as="div" className="m-1">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="flex items-center justify-start text-left text-black space-x-2">
                          <span className="flex h-7 items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-4 w-4 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                          <span className="font-light text-[13px] leading-[37px] text-primary-mono-1">
                            {textUnderButton}
                          </span>
                        </Disclosure.Button>
                      </dt>

                      <Disclosure.Panel as="dd">
                        <PricingTable 
                          basicProductWithPrices={basicProductWithPrices}
                          premiumProductWithPrices={premiumProductWithPrices}
                          familyProductWithPrices={familyProductWithPrices}
                          forEverCoupon={forEverCoupon}/>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <div className="mx-12 sm:mx-0 ">
                  <Image
                    src={urlFor(paymentIcons, true).url()}
                    width={200}
                    height={45}
                    alt="payment"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalizedImageAndProduct;
