import { Image as ImageType } from "../../lib/types";
import Image from "next/image";
import { getPositionFromHotspot } from "../../lib/helpers";

interface Props {
  bubbleText: string;
  header: string;
  leftAppImage: ImageType;
  rightAppImage: ImageType;
  middleText: string;
  subText: string;
  urlFor: Function;
}
const LocalizedHotelEcosystem = (props: Props) => {
  const {
    bubbleText,
    header,
    leftAppImage,
    middleText,
    subText,
    urlFor,
    rightAppImage,
  } = props;
  return (
    <div className="w-full relative bg-primary-mono-3 mt-6 md:mt-12">
      <h1 className="text-3xl font-bold text-primary-mono-1 font-p22 place-self-center py-4 md:py-12 md:text-5xl">
        {header}
      </h1>
      <div className="bg-primary-mono-3 md:pt-12 md:mb-12 lg:pt-24 lg:mb-24">
        <div className="max-w-7xl mx-auto flex flex-col items-center justify-start font-p22 p-4 min-h-[450px] relative">
          <div className="flex flex-col w-full">
            <div className="relative w-full bottom-0 right-0 h-[300px] z-10 md:right-24 md:absolute md:w-2/3 md:h-[500px]">
              <Image
                src="/backgrounds/Rendered_cages.svg"
                layout="fill"
                objectFit="contain"
              />
              {/* <div className="bg-primary-mono-4/90 w-44 h-44 rounded-full flex items-center justify-center px-2 md:mx-2 absolute top-0 -right-2 z-0 md:hidden">
                <div className="relative flex items-center justify-center">
                  <h4 className="text-lg text-primary-mono-1 font-bold text-center">
                    {bubbleText}
                  </h4>
                </div>
              </div> */}
            </div>
            <div className="h-[400px] z-10 rotate-6 self-center absolute w-1/3 right-8 lg:hidden">
              <Image
                src={urlFor(rightAppImage).url()}
                layout="fill"
                objectFit="contain"
              />
            </div>
          </div>
          <div className="relative hidden -bottom-10 left-14 w-full h-[600px] z-10 rotate-12 lg:block md:absolute md:w-[290px]">
            <Image
              src={urlFor(leftAppImage).url()}
              layout="fill"
              objectFit="contain"
            />
          </div>
          <div className="relative hidden -bottom-20 right-14 w-full h-[600px] z-10 -rotate-6 lg:block md:absolute md:w-[290px]">
            <Image
              src={urlFor(rightAppImage).url()}
              layout="fill"
              objectFit="contain"
            />
          </div>
          <div className="bg-primary-mono-4/60 w-44 h-44 rounded-full absolute -top-20 left-44 z-0 hidden lg:block" />
          <div className="bg-primary-mono-4/60 w-32 h-32 rounded-full absolute top-64 left-10 z-0 hidden lg:block" />
          <div className="bg-primary-mono-4/60 w-44 h-44 rounded-full absolute top-36 right-72 z-0 hidden lg:block" />
          <div className="bg-primary-mono-4/60 w-44 h-44 rounded-full absolute top-10 right-20 z-0 hidden lg:block" />
        </div>
        <div className="h-24 bg-white w-full absolute -bottom-24 z-30 hidden lg:block" />
      </div>
    </div>
  );
};

export default LocalizedHotelEcosystem;
