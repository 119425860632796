import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { BsStar, BsStarFill, BsArrowLeft, BsArrowRight } from "react-icons/bs";

const Reviews = ({ reviews }) => {
  const [hasOverflow, setHasOverflow] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const container = ref.current;

    const checkOverflow = () => {
      if (container) {
        const hasHorizontalOverflow =
          container.scrollWidth > container.clientWidth;
        setHasOverflow(hasHorizontalOverflow);
      }
    };

    const handleResize = () => {
      checkOverflow();
    };

    window.addEventListener("resize", handleResize);
    checkOverflow();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollRight = () => {
    ref.current.scrollLeft += 256 * 3;
  };

  const scrollLeft = () => {
    ref.current.scrollLeft -= 256 * 3;
  };
  return (
    <div className="relative w-screen h-[480px] pt-2 flex flex-col">
      <div
        className="scroll-container flex space-x-2 font-objektiv relative w-full overflow-x-scroll"
        ref={ref}
      >
        {reviews?.data?.map((review, i) => {
          const userPhotoUrl = "" + review.image;
          return (
            <div
              key={i}
              className="w-64 h-[450px] shrink-0 shadow-small rounded-sm "
            >
              <div className="relative w-full h-[240px]">
                <Image src={userPhotoUrl} layout="fill" objectFit="cover" />
              </div>
              <div className="relative items-center justify-center flex">
                <div className="flex space-x-1 bg-white shadow-md w-min py-3 px-4 rounded-md absolute -top-4">
                  {[...Array(5)].map((_, i) =>
                    i < review.rating ? (
                      <BsStarFill key={i} color="#b5cf8f" />
                    ) : (
                      <BsStar key={i} />
                    )
                  )}
                </div>
                <div className="p-2 mt-12 text-center">
                  <h6 className="font-semibold">{review.author}</h6>
                  {/* <h6 className="font-semibold">{review.author}</h6> */}
                  <p className="text-[13px]">{review.title}</p>
                  {/* <p className="text-sm">{review.reviewMessage}</p> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <button
        className="absolute bottom-2 left-4 text-primary-1 bg-primary-4 p-2 rounded shadow-md hover:scale-110 transition-transform"
        onClick={scrollLeft}
      >
        <BsArrowLeft size={16} />
      </button>
      <button
        className="absolute bottom-2 right-4 text-primary-1 bg-primary-4 p-2 rounded shadow-md hover:scale-110 transition-transform"
        onClick={scrollRight}
      >
        <BsArrowRight size={16} />
      </button>
    </div>
  );
};

export default Reviews;
