import React, { useState, useEffect } from "react";
import { useLocale } from "../translations";

interface Props {
  targetDate: Date;
}

const CountdownTimer = (props: Props) => {
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const urlPrefix = useLocale().urlPrefix.toString();

  useEffect(() => {
    const updateRemainingTime = () => {
      const currentTime = new Date().getTime();
      const timeLeft = props.targetDate.getTime() - currentTime;

      setTimeRemaining(Math.max(timeLeft, 0)); 
    };

    updateRemainingTime();

    const interval = setInterval(() => {
      updateRemainingTime();
    }, 1000);

    return () => clearInterval(interval);
  }, [props.targetDate]);

  if (timeRemaining === null) {
    return <div>Loading timer...</div>;
  }

  const formatTime = (time: number) => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    const seconds = Math.floor((time / 1000) % 60);
    return { days, hours, minutes, seconds };
  };

  const timeData = formatTime(timeRemaining);

  return (
    <div className="w-3/5 flex items-center text-center justify-center">
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className="flex flex-col">
            <span className="w-20 flex-shrink-0">
              <p className="text-3xl md:text-2xl lg:text-3xl">
                {timeData.days}
              </p>
            </span>
            <p className="text-xs -translate-y-2">
              {translations.days[urlPrefix] || translations.days.en}
            </p>
          </div>
          <p className="text-lg md:text-2xl">:</p>
          <div className="flex flex-col">
            <span className="w-20 flex-shrink-0">
              <p className="text-3xl md:text-2xl lg:text-3xl">
                {timeData.hours}
              </p>
            </span>
            <p className="text-xs -translate-y-2">
              {translations.hours[urlPrefix] || translations.hours.en}
            </p>
          </div>
          <p className="text-lg md:text-2xl">:</p>
          <div className="flex flex-col">
            <span className="w-20 flex-shrink-0">
              <p className="text-3xl md:text-2xl lg:text-3xl">
                {timeData.minutes}
              </p>
            </span>
            <p className="text-xs -translate-y-2">
              {translations.minutes[urlPrefix] || translations.minutes.en}
            </p>
          </div>
          <p className="text-lg md:text-2xl">:</p>
          <div className="flex flex-col">
            <span className="w-20 flex-shrink-0">
              <p className="text-3xl md:text-2xl lg:text-3xl">
                {timeData.seconds}
              </p>
            </span>
            <p className="text-xs -translate-y-2">
              {translations.seconds[urlPrefix] || translations.seconds.en}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const translations = {
  days: {
    no: "dager",
    en: "days",
    dk: "dage",
    de: "Tage",
    se: "dagar",
    fr: "jours",
    es: "días",
  },
  hours: {
    no: "timer",
    en: "hours",
    dk: "timer",
    de: "Stunden",
    se: "timmar",
    fr: "heures",
    es: "horas",
  },
  minutes: {
    no: "min",
    en: "min",
    se: "min",
    dk: "min",
    de: "min",
    fr: "min",
    es: "min",
  },
  seconds: {
    no: "sek",
    en: "sec",
    se: "sek",
    dk: "sek",
    de: "sek",
    fr: "sec",
    es: "seg",
  },
};

export default CountdownTimer;
