import React from "react";
import { useLocale } from "../../translations";
import CountdownTimer from "../CountdownTimer";

import { InformationCircleIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
interface Props {
  textcenter: string;
  endDateTime: Date;
  mainProductPrices: {
    PRICE: any;
    ORIGINAL_PRICE: any;
  };
  productTaxRate: string;
  locale: {
    defaultCurrency: string;
  };
  linkButton: {
    buttonText: string;
    url: string
  };
  infoBanner: {
    title: string;
    text: string;
    iconText: string;
  }
}

const LocalizedSectionBlackFriday = (props: Props) => {
  const { textcenter, endDateTime, mainProductPrices, productTaxRate, locale, linkButton, infoBanner } =
    props;
  const urlPrefix = useLocale().urlPrefix;
  const endDate = new Date(endDateTime.toString());

  const calculatePrice = (price: string, taxRate: number) => {
    const priceNumber = parseFloat(price);
    const priceWithTax = priceNumber * (1 + taxRate / 100);
    return priceWithTax.toFixed(0);
  };

  const originalPrice = calculatePrice(
    mainProductPrices?.ORIGINAL_PRICE?.[locale?.defaultCurrency] ?? null,
    parseFloat(productTaxRate)
  );
  const price = calculatePrice(
    mainProductPrices?.PRICE?.[locale?.defaultCurrency] ?? null,
    parseFloat(productTaxRate)
  );

  const discount = parseFloat(originalPrice) - parseFloat(price);
  return (
    <>
      <div className="flex flex-col bg-black w-full h-fit mt-4 text-white text-center">
        <p className="mt-4 col-start-1 col-span-3  md:col-start-2 md:col-span-1 text-3xl md:text-4xl lg:text-5xl underline font-bold ">
          {textcenter ? textcenter : translations.blackFriday.en}
        </p>
        <div className={`grid grid-cols-1 ${infoBanner?.title? 'md:grid-cols-2' : ''} my-4 justify-center`}>
          <div className="hidden md:flex flex-col items-center justify-center">
            <p className="text-sm">
              {translations.timer[urlPrefix]
                ? translations.timer[urlPrefix]
                : translations.timer.en}
            </p>
            <CountdownTimer targetDate={endDate} />
          </div>
          {infoBanner?.title && <div className="flex flex-col items-center text-center mr-4 sm:mr-8 md:mr-12 lg:mr-16 justify-center">
            <div className="w-4/5   bg-white rounded-2xl items-center text-center flex justify-center flex-col md:flex-row ">
              <InformationCircleIcon title={infoBanner.iconText} className="h-8 w-8 fill-black hidden md:flex " />
              <p className="text-xs lg:text-sm text-black md:ml-2 my-1 md:my-2">
                {infoBanner?.title || ""}
              </p>
              <p className="text-sm lg:text-lg text-red-800 md:mx-2">
                {infoBanner?.text || ""}
              </p>
            </div>
          </div>}
        </div>
        <div className="flex flex-col md:hidden text-center items-center justify-center mb-4">
          <p className="text-sm">
            {translations.timer[urlPrefix]
              ? translations.timer[urlPrefix]
              : translations.timer.en}
          </p>
          <CountdownTimer targetDate={endDate} />
        </div>
        {linkButton?.buttonText && linkButton?.url && <div className="flex w-full mb-6 justify-center items-center font-default text-base md:text-lg pt-2">
          <Link href={linkButton?.url || "/"}>
            <a target="_blank" rel="noopener noreferrer">
              <div className="flex text-center items-center justify-center bg-primary-4 rounded-xl h-12 w-full px-8 hover:scale-110 transition-transform">
                <p className="text-primary-1 ">
                  {linkButton?.buttonText || ""}
                </p>
              </div>
            </a>
          </Link>
        </div>}
      </div>
    </>
  );
};

const translations = {
  timer: {
    no: "Tilbudet utløper om",
    en: "Offer expires in",
    se: "Erbjudandet löper ut om",
    dk: "Tilbuddet udløber om",
    fi: "Tarjous päättyy",
    de: "Angebot endet in",
    fr: "L'offre expire dans",
    es: "La oferta expira en",
  },
  curr: {
    NOK: ",-",
    USD: "$",
    EUR: "€",
  },
  blackFriday: {
    en: "Black Friday tøys",
  },
};
export default LocalizedSectionBlackFriday;
